/* eslint-disable no-nested-ternary */
import React, { useContext, useMemo, useState } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { centered } from '@/components/use-shared-element';
import { useAudienceQueryBuilder } from '@/features/editor/widgets/shared/modals/use-audience-query-builder';
import { useComplexState } from '@/utils/use-complex-state';
import {
  FacebookTargeting,
  QBItemProp,
  QBItemSelection,
} from '@/components/query-builder/models';
import { PlacementKind, Segment, Targeting } from '@/webapi/use-experience-api';
import { FBView } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/context';
import { Audience } from '@/features/dashboard/audiences/models';
import { makeAudienceObject } from '@/features/dashboard/audiences/context';
import { useAudiencesApi } from '@/features/dashboard/audiences/api';

interface AudienceModel {
  kind: Segment;
  name: string;
  description: string;
}

export interface AudienceModalContext {
  onSave: (facebook?: FacebookTargeting, close?: boolean) => void;
  onBackToSelection: () => void;
  onPredefined: (isSelected: boolean, s: Segment) => void;
  audienceProps: QBItemProp[];
  onAudienceSave: () => void;
  loading: boolean;
  onAllVisitors: (isSelected: boolean) => void;
  onFacebook: (isSelected: boolean) => void;
  audiences: AudienceModel[];
  onNameAudience: () => void;
  onCloseCleanup: () => void;
  toRect: DOMRect;
  onBackToCustom: () => void;
  onQueryBuilderChange: (value: QBItemSelection[]) => void;
  onCustom: (isSelected: boolean) => void;
  customSegment: QBItemSelection[];
  selected: (Segment | string)[];
  view: View | FBView;
  onUserDefined: (isSelected: boolean, audience: Audience) => void;
  userDefinedAudienceId?: string;
  setUserDefinedAudienceName: (name: string) => void;
  setUserDefinedAudienceDesc: (name: string) => void;
  userDefinedAudienceName: string;
  userDefinedAudienceDesc: string;
  isCheckout?: boolean;
}

export enum View {
  SELECT = `SELECT`,
  CUSTOM = `CUSTOM`,
  FACEBOOK = `FACEBOOK`,
  AUDIENCE_NAME = `AUDIENCE_NAME`,
}

export const AudienceContext = React.createContext({} as AudienceModalContext);

export function newAudienceModalCtx(
  setIsVisible: (value: boolean) => void,
  fbReInit: () => Promise<void>,
  fbClean: () => any,
): AudienceModalContext {
  const {
    resources,
    newAudiences,
    setNewAudiences,
    experienceState: {
      currentExperience,
      setTargetingAudience,
      getCurrentVariant,
    },
  } = useContext(EditorContext);
  const [userDefinedAudienceName, setUserDefinedAudienceName] = useState(``);
  const [userDefinedAudienceDesc, setUserDefinedAudienceDesc] = useState(``);

  const { loading, createAudience, updateAudience } = useAudiencesApi(false);
  const toRect = centered(60, 90);
  const { changes } = getCurrentVariant();

  const { targeting, quickPreviewPlacement: quickPreviewPage } =
    currentExperience;

  const isCheckout =
    !!changes?.find(
      (change) => change.targetPlacement?.kind === PlacementKind.CHECKOUT,
    ) ||
    (quickPreviewPage && quickPreviewPage === PlacementKind.CHECKOUT);

  const { audienceProps } = useAudienceQueryBuilder(
    resources,
    targeting?.other,
    isCheckout,
  );

  const initialState = (targeting.segments || []).map((s) =>
    typeof Segment[s] === `undefined` ? s : Segment[s],
  );
  const [selected, setSelected] =
    useComplexState<(Segment | string)[]>(initialState);

  const [customSegment, setCustomSegment] = useState(targeting?.other);
  const [userDefinedAudienceId, setUserDefinedAudienceId] = useState(
    targeting?.userDefinedAudienceId,
  );

  const [view, setView] = useState<View>(getInitialView(targeting));

  const onPredefined = (isSelected: boolean, s: Segment) => {
    setSelected((clone) => {
      if (!isSelected) {
        clone.push(s);
      } else {
        const idx = clone.findIndex((seg) => seg === s);
        clone.splice(idx, 1);
      }
    });
    setView(View.SELECT);
    fbClean();
    setCustomSegment(undefined);
    setUserDefinedAudienceId(``);
  };

  const onAllVisitors = (isSelected: boolean) => {
    if (isSelected) {
      setSelected((clone) => {
        clone = [];
        return clone;
      });
      setView(View.SELECT);
    }
    setCustomSegment(undefined);
    setUserDefinedAudienceId(``);
  };

  const onCustom = (isSelected: boolean) => {
    setUserDefinedAudienceId(``);
    setUserDefinedAudienceName(``);
    setUserDefinedAudienceDesc(``);
    if (isSelected) {
      setSelected((clone) => {
        clone = [];
        return clone;
      });
      if (userDefinedAudienceId) {
        setCustomSegment([]);
      }
      setView(View.CUSTOM);
    }
  };

  const onFacebook = (isSelected: boolean) => {
    if (isSelected) {
      fbReInit().then(() => {
        setSelected((clone) => {
          clone = [];
          return clone;
        });
        setUserDefinedAudienceId(``);
        setView(View.FACEBOOK);
      });
    }
  };

  const onUserDefined = (isSelected: boolean, audience: Audience) => {
    if (isSelected) {
      setUserDefinedAudienceId(``);
      setCustomSegment(undefined);
    } else {
      setSelected((clone) => {
        clone = [];
        return clone;
      });
      setCustomSegment(audience.targeting.other);
      setUserDefinedAudienceId(audience.id);
    }
    fbClean();
  };

  const onAudienceSave = async () => {
    if (newAudiences?.length > 0) {
      const idx = newAudiences.length - 1;
      newAudiences[idx].targeting.other = customSegment;
      newAudiences[idx].name = userDefinedAudienceName;
      newAudiences[idx].description = userDefinedAudienceDesc;
      setUserDefinedAudienceId(newAudiences[idx].id);
      setNewAudiences(newAudiences);
      await updateAudience(newAudiences[idx]);
      setView(View.CUSTOM);
      return;
    }
    const audience = (await createAudience(
      makeAudienceObject(
        userDefinedAudienceName,
        userDefinedAudienceDesc,
        customSegment,
      ),
    )) as Audience;
    setUserDefinedAudienceId(audience.id);
    setNewAudiences([...newAudiences, audience]);
    setView(View.CUSTOM);
  };

  const onSave = async (facebook?: FacebookTargeting, close = true) => {
    if (userDefinedAudienceId) {
      setView(View.SELECT);
    }
    setTargetingAudience(
      selected,
      customSegment,
      facebook,
      userDefinedAudienceId,
    );
    close && setIsVisible(false);
  };

  const onNameAudience = () => {
    setView(View.AUDIENCE_NAME);
  };

  const onBackToCustom = () => {
    if (view === View.AUDIENCE_NAME) {
      setView(View.CUSTOM);
    }
  };
  const onBackToSelection = () => {
    setSelected((clone) => {
      clone = [];
      return clone;
    });
    setView(View.SELECT);
    setCustomSegment(undefined);
  };

  const onQueryBuilderChange = (value: QBItemSelection[]) => {
    setView(View.CUSTOM);
    setSelected((clone) => {
      clone = [];
      return clone;
    });
    setCustomSegment([...value]);
  };

  const audiences: AudienceModel[] = getAudiences();
  const onCloseCleanup = () => {
    setSelected((draft) => {
      draft = initialState;
      return draft;
    });
    setCustomSegment(targeting.other);
    setUserDefinedAudienceId(targeting.userDefinedAudienceId);
    setView(getInitialView(targeting));
  };

  return {
    view,
    onCloseCleanup,
    onNameAudience,
    setUserDefinedAudienceName,
    setUserDefinedAudienceDesc,
    userDefinedAudienceName,
    userDefinedAudienceDesc,
    audiences,
    onBackToCustom,
    toRect,
    onSave,
    onBackToSelection,
    onQueryBuilderChange,
    onCustom,
    audienceProps,
    onAudienceSave,
    loading,
    customSegment,
    selected,
    onAllVisitors,
    onPredefined,
    onFacebook,
    onUserDefined,
    userDefinedAudienceId,
    isCheckout,
  };
}

function getAudiences() {
  return useMemo(
    () => [
      {
        kind: Segment.FIRST_SESSION,
        name: `New Visitors`,
        description: `Brand new visitors`,
      },
      {
        kind: Segment.RETURNING_VISITORS,
        name: `Returning Visitors`,
        description: `Returning visitors`,
      },
      {
        kind: Segment.VIEWED_PRODUCTS,
        name: `Viewed Products`,
        description: `Customers that at least viewed some of the products you offer`,
      },
      {
        kind: Segment.PURCHASED_RECENTLY,
        name: `Purchased Recently`,
        description: `Customers that made a recent purchase in your store`,
      },
      {
        kind: Segment.PURCHASED_LAST_WEEK,
        name: `Purchased Last Week`,
        description: `Customers that made a purchase in the last week`,
      },
      {
        kind: Segment.NEVER_PURCHASED,
        name: `Never Purchased`,
        description: `Visitors that never made a purchase in your store`,
      },
      {
        kind: Segment.ONE_TIMERS,
        name: `One Timers`,
        description: `Customers that have purchased only once`,
      },
      {
        kind: Segment.ABANDONED_CARTS,
        name: `Cart Abandons`,
        description: `Visitors that has items in cart and didn't complete their order`,
      },
      {
        kind: Segment.SUBSEQUENT_SESSIONS,
        name: `First session & subsequent sessions`,
        description: `As long as the experience is active it will continue to be presented to the visitor after his initial visit`,
      },
      {
        kind: Segment.CUSTOMERS,
        name: `Logged-in Users`,
        description: `Visitors who have logged in`,
      },
      {
        kind: Segment.GUESTS,
        name: `Guests`,
        description: `Visitors who have not logged in`,
      },
    ],
    [],
  );
}
function getInitialView(targeting: Targeting) {
  if (targeting.userDefinedAudienceId) {
    return View.SELECT;
  }
  if (targeting.other) {
    return View.CUSTOM;
  }
  if (targeting?.facebook) {
    return View.FACEBOOK;
  }
  return View.SELECT;
}
