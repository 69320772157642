import React, { FC, useRef, useState } from 'react';
import styled from 'styled-components';
import { ArrowContainer, Popover, PopoverPosition } from 'react-tiny-popover';
import { VscChromeClose } from 'react-icons/vsc';
import ReactTooltip from 'react-tooltip';

interface CP {
  backgroundColor?: string;
}

const Container = styled.div<CP>`
  z-index: 999999;
  position: relative;
  background-color: ${(p: CP) => p.backgroundColor || `#f4f6f7`};
  font-family: 'JetBrains Mono', serif;
  color: #82828a;
  width: ${(p) => p.width || `350px`};
  padding: 1rem 2rem;
  border-radius: 15px;
  box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.15),
    0 4px 11px 0 rgba(151, 210, 206, 0.22);
  user-select: none;
  p.title {
    font-size: 1.6rem;
    font-weight: 900;
  }
  p.text {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

type Props = {
  title: string;
  text: string | any;
  positions?: PopoverPosition[];
  zIndex?: number;
  forceHide?: boolean;
  width?: string;
  backgroundColor?: string;
};

export const Tooltip: FC<Props> = ({
  text,
  title,
  positions,
  zIndex,
  children,
  forceHide,
  width,
  backgroundColor,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<HTMLButtonElement | undefined>();

  const toggle = () => setOpen((prev) => !prev);

  return (
    <Popover
      containerStyle={{ zIndex: `${zIndex || 30}` }}
      isOpen={forceHide ? false : open}
      positions={positions || [`top`]}
      padding={10}
      onClickOutside={toggle}
      ref={ref}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor="#f4f6f7"
          arrowSize={10}
          arrowStyle={{ opacity: 0.8 }}
          className="popover-arrow-container"
          arrowClassName="popover-arrow"
        >
          <Container
            className="tooltip-container"
            width={width}
            backgroundColor={backgroundColor}
          >
            <p className="title">{title}</p>
            <p className="text">{text}</p>
            {!children && (
              <Close onClick={toggle}>
                <VscChromeClose color="#82828a" size={20} />
              </Close>
            )}
          </Container>
        </ArrowContainer>
      )}
    >
      {children ? (
        <div
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          data-testid="popover-btn"
        >
          {children}
        </div>
      ) : (
        <Question
          className="tooltip-question-mark"
          onMouseEnter={() => {
            setOpen(true);
          }}
          onMouseLeave={() => {
            setOpen(false);
          }}
          data-testid="popover-btn"
          type="button"
          zIndex={zIndex}
          onClick={toggle}
        >
          ?
        </Question>
      )}
    </Popover>
  );
};

const Close = styled.button`
  position: absolute;
  font-family: 'JetBrains Mono', serif;
  font-weight: 900;
  user-select: none;
  color: #82828a;
  opacity: 0.7;
  top: 10px;
  left: 87%;

  :hover {
    opacity: 0.5;
    cursor: pointer;
  }

  :active {
    opacity: 1;
    cursor: default;
  }
`;
const Question = styled.button`
  z-index: ${(p: { zIndex?: number }) =>
    typeof p.zIndex !== `undefined` ? p.zIndex : 29};
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 1rem;
  color: #919191;
  background-color: #e1e4ed;
  border-radius: 50%;
  font-size: 12px;
  width: 16px;
  height: 16px;

  :hover {
    opacity: 0.5;
    box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.09), 0 1px 4px 0 rgba(7, 6, 6, 0.04);
    cursor: pointer;
  }

  :active {
    opacity: 1;
    cursor: default;
  }
  box-sizing: border-box;
`;

export function DescriptionTooltip({
  id,
  description,
}: {
  id: string;
  description?: string;
}) {
  return description?.length > 62 ? (
    <ReactTooltip
      id={`desc${id}`}
      place="bottom"
      backgroundColor="#fff"
      textColor="#000"
      className="exp-desc-tooltip"
    >
      {description}
    </ReactTooltip>
  ) : null;
}
