import { useContext } from 'react';
import {
  hasAllVariantPrimaryAttrOptions,
  LoadedEditorResources,
} from '@/features/editor/use-editor-loader';
import { SelectOptions } from '@/components/small-select';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PlacementKind } from '@/webapi/use-experience-api';
import {
  QBItemProp,
  QBItemSelection,
  QBType,
} from '@/components/query-builder/models';
import { NumberValueOps } from '@/components/query-builder/numeric-value';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { removeDuplicatesByKey } from '@/utils/arrays';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { getVariantAttributes } from '@/features/editor/widgets/custom-widget/loading-section/conditional-product/useConditionalQueryBuilder';
import { Product } from '@/webapi/use-catalog-api';
import { getProductValue } from '@/features/editor/widgets/shared/modals/use-audience-query-builder';

export const productPageGroup = `For Product Page`;

export function useQueryBuilder(isPostPurchase?: boolean) {
  const {
    resources,
    experienceState: {
      currentExperience: { quickPreviewPlacement },
    },
  } = useContext(EditorContext);
  const uniqProducts = uniqueList(resources.products);
  const products = transformProducts(uniqProducts);
  const productsById = transformProductsWithIds(uniqProducts);
  const categories = transformCategories(resources);
  if (quickPreviewPlacement === PlacementKind.ALL_PRODUCTS) {
    categories.splice(1, 0, {
      key: `product_collections`,
      value: `Viewed Item Collections`,
    } as SelectOptions);
  }
  const andOrEnabled = useFeatureBit(FeatureBit.LAYERED_RULING_AND_OR);

  const condition: QBItemProp[] = [
    {
      envKey: `tag`,
      group: `For Cart / Checkout`,
      kind: QBType.ARRAY_VALUE,
      caption: `Items in the cart with tag`,
      disableOrCond: !andOrEnabled,
      options: resources?.tags?.map(
        (tag) =>
          ({
            key: tag,
            value: tag,
          } as SelectOptions),
      ),
    },
    {
      envKey: `category`,
      group: `For Cart / Checkout`,
      kind: QBType.MULTI_VALUE,
      caption: `Items in the cart from collection`,
      disableOrCond: !andOrEnabled,
      options: categories,
    },
    {
      envKey: `cartPrice`,
      group: `For Cart / Checkout`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Cart Total`,
      hasCurrencyCode: true,
      disableOrCond: !andOrEnabled,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `cartSize`,
      group: `For Cart / Checkout`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Cart Size`,
      disableOrCond: !andOrEnabled,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      kind: QBType.MULTI_VALUE,
      caption: `Specific item in the cart`,
      options: products,
      envKey: `item`,
      disableOrCond: !andOrEnabled,
      group: `For Cart / Checkout`,
    },
    ...(!isPostPurchase
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Seen Product`,
            options: productsById,
            envKey: `product_seen`,
            disableOrCond: !andOrEnabled,
            group: productPageGroup,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Product from collection`,
            options: categories,
            envKey: `product_from_collection`,
            disableOrCond: !andOrEnabled,
            group: productPageGroup,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Product with tag`,
            options: transformTags(resources) || [],
            envKey: `product_with_tag`,
            disableOrCond: !andOrEnabled,
            group: productPageGroup,
          },
        ]
      : []),
  ];

  return { condition };
}

const sortByTitle = (a, b) => {
  if (a.title < b.title) {
    return -1;
  }
  if (a.title > b.title) {
    return 1;
  }
  return 0;
};

export function transformProducts(resources: Array<Product>) {
  return removeDuplicatesByKey(
    resources.sort(sortByTitle).map(
      (col) =>
        ({
          key: col.handle,
          image: col?.image?.src,
          value: getProductValue(col),
        } as SelectOptions),
    ),
    `key`,
  );
}

export function uniqueList(objects: Array<Product>) {
  const uniqueMap = {};
  objects.forEach((obj) => {
    uniqueMap[obj.handle] = obj;
  });
  return Object.values(uniqueMap) as Array<Product>;
}

export function transformProductsWithIds(resources: Array<Product>) {
  return resources.sort(sortByTitle).map(
    (col) =>
      ({
        key: col.id,
        image: col?.image?.src,
        value: getProductValue(col),
      } as SelectOptions),
  );
}

export function transformCategories(resources: LoadedEditorResources) {
  return resources?.collections?.map(
    (col) =>
      ({
        key: col.handle,
        value: col.title,
      } as SelectOptions),
  );
}

export function transformTags(resources: LoadedEditorResources) {
  return resources?.tags?.map(
    (tag) =>
      ({
        key: tag,
        value: tag,
      } as SelectOptions),
  );
}

export function isProductPageRule(current: {
  cond: Array<QBItemSelection>;
  itemSelection: string;
}) {
  return current.cond?.[0]?.qbProps?.group === productPageGroup;
}

export function useConditionalQueryBuilder(strategyType: RecommendationType) {
  const {
    resources,
    experienceState: {
      currentExperience: { quickPreviewPlacement },
    },
  } = useContext(EditorContext);
  const isEnabled =
    useFeatureBit(FeatureBit.VARIANT_ATTRIBUTES) &&
    hasAllVariantPrimaryAttrOptions(resources);
  if (strategyType === RecommendationType.MANUAL) {
    return {
      condition: [
        {
          envKey: `inventory`,
          kind: QBType.NUMERIC_VALUE,
          caption: `Inventory`,
          disableOrCond: true,
          defaultNumericValue: 0,
          defaultNumericValueOp: NumberValueOps.GT,
        },
        {
          envKey: `variantPerProduct`,
          kind: QBType.MULTI_VALUE,
          caption: `Separate out color variants`,
          disableOrCond: true,
          disableOp: true,
          options: [
            { key: `yes`, value: `yes` },
            { key: `no`, value: `no` },
          ],
        },
        {
          envKey: `includeCartItems`,
          kind: QBType.MULTI_VALUE,
          caption: `Include Cart Items`,
          disableOrCond: true,
          disableOp: true,
          options: [
            { key: `no`, value: `no` },
            { key: `yes`, value: `yes` },
          ],
        },
      ],
    };
  }
  const collections = resources.collections.map(
    (col) =>
      ({
        key: col.handle,
        value: col.title,
      } as SelectOptions),
  );
  if (quickPreviewPlacement === PlacementKind.ALL_PRODUCTS) {
    collections.splice(1, 0, {
      key: `product_collections`,
      value: `Viewd Item Collections`,
    } as SelectOptions);
  }

  const tags = resources?.tags?.map(
    (tag) =>
      ({
        key: tag,
        value: tag,
      } as SelectOptions),
  );

  const isEnabledSameTagsRecs =
    useFeatureBit(FeatureBit.RECS_SAME_TAG_COL) &&
    ![
      RecommendationType.PAST_PURCHASES,
      RecommendationType.RECENTLY_VIEWED,
      RecommendationType.CART_ITEMS,
    ].includes(strategyType);

  const condition: QBItemProp[] = [
    ...(strategyType === RecommendationType.PAST_PURCHASES
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `PURCHASED ITEM`,
            hasTimeframe: true,
            disableOrCond: true,
            options: [{ key: `True`, value: `True` }],
            envKey: RecommendationType.PAST_PURCHASES,
          },
        ]
      : []),
    ...(strategyType === RecommendationType.CART_ITEMS
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `ITEM ADDED TO CART`,
            hasTimeframe: true,
            disableOrCond: true,
            options: [{ key: `True`, value: `True` }],
            envKey: RecommendationType.CART_ITEMS,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `ITEM IS STILL IN THE CART`,
            hasTimeframe: false,
            disableOrCond: true,
            options: [
              { key: `True`, value: `True` },
              { key: `False`, value: `False` },
            ],
            envKey: RecommendationType.CART_ITEMS,
          },
        ]
      : []),
    {
      kind: QBType.ARRAY_VALUE,
      caption: `PRODUCTS WITH TAG`,
      options: tags,
      envKey: `tag`,
    },
    ...(isEnabledSameTagsRecs
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Include only items matching the same Tag`,
            disableOp: true,
            options: [
              { key: `all_matching_tags`, value: `All matching tags` },
              ...tags,
            ],
            envKey: `same_tag`,
          },
          {
            envKey: `same_collection`,
            kind: QBType.ARRAY_VALUE,
            disableOp: true,
            caption: `Include only items matching the same Collection`,
            options: [
              {
                key: `all_matching_collections`,
                value: `All matching collections`,
              },
              ...collections,
            ],
          },
        ]
      : []),
    {
      envKey: `category`,
      kind: QBType.MULTI_VALUE,
      caption: `PRODUCTS FROM COLLECTIONS`,
      options: collections,
    },
    {
      kind: QBType.ARRAY_VALUE,
      caption: `Product Name`,
      options: resources?.products?.map(
        (p) =>
          ({
            key: p.handle,
            image: p?.image?.src,
            value: getProductValue(p),
          } as SelectOptions),
      ),
      defaultIsNot: true,
      envKey: `product`,
    },
    {
      envKey: `inventory`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Inventory`,
      disableOrCond: true,
      defaultNumericValue: 0,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `price`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Price`,
      disableOrCond: true,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `free_shipping`,
      kind: QBType.NUMERIC_VALUE,
      caption: `free shipping goal`,
      disableOrCond: true,
      hasCurrencyCode: true,
      explain: `For example, if the cart total is $40 and the goal is $50, we will show products that cost more than $10`,
      hideCurrencyCodeCheckbox: true,
      disableOp: true,
      defaultNumericValueOp: NumberValueOps.IS,
    },
    ...(resources?.reviews?.reviewsEnabled
      ? [
          {
            envKey: `reviewsCount`,
            kind: QBType.NUMERIC_VALUE,
            caption: `Reviews Count`,
            disableOrCond: true,
            defaultNumericValueOp: NumberValueOps.GT,
          },
          {
            envKey: `reviewsRating`,
            kind: QBType.NUMERIC_VALUE,
            caption: `Reviews Rating`,
            disableOrCond: true,
            defaultNumericValueOp: NumberValueOps.GT,
          },
        ]
      : []),
    ...(isEnabled
      ? [
          {
            envKey: `variantAttributes`,
            kind: QBType.ARRAY_VALUE,
            caption: `${resources.mainAttribute} (Variant primary attribute)`,
            options: getVariantAttributes(resources, isEnabledSameTagsRecs),
          },
          {
            envKey: `shuffle`,
            kind: QBType.MULTI_VALUE,
            caption: `Randomly Shuffle Results`,
            disableOrCond: true,
            disableOp: true,
            options: [
              { key: `no`, value: `no` },
              { key: `yes`, value: `yes` },
            ],
          },
          {
            envKey: `oos50p`,
            kind: QBType.MULTI_VALUE,
            caption: `Display products with no stock in most sizes`,
            disableOrCond: true,
            disableOp: true,
            options: [
              { key: `no`, value: `no` },
              { key: `yes`, value: `yes` },
            ],
          },
        ]
      : []),
    {
      envKey: `variantPerProduct`,
      kind: QBType.MULTI_VALUE,
      caption: `Separate out color variants`,
      disableOrCond: true,
      disableOp: true,
      options: [
        { key: `yes`, value: `yes` },
        { key: `no`, value: `no` },
      ],
    },
    {
      envKey: `includeCartItems`,
      kind: QBType.MULTI_VALUE,
      caption: `Include Cart Items`,
      disableOrCond: true,
      disableOp: true,
      options: [
        { key: `no`, value: `no` },
        { key: `yes`, value: `yes` },
      ],
    },
  ];

  return { condition };
}
