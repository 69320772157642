import * as React from 'react';
import styled from 'styled-components';
import { useContext } from 'react';
import { GradientLayout } from '@/components/gradient-layout';
import { CloseEditorButton } from '@/features/editor/widgets/shared/layout/close-editor-button';
import { BackEditorButton } from '@/features/editor/widgets/shared/layout/back-editor-button';
import { VSpace } from '@/components/spacing';
import { TinyProgressBar } from '@/components/tiny-progress-bar';
import { DefaultTypography } from '@/components/typography';
import { useIsPreviewingDesktop } from '@/features/editor/shared/use-device-type';
import { EditorContext } from '@/features/editor/context/editor-context';
import { WidgetType } from '@/utils/definitions';

export interface InspectorWidgetLayoutProps {
  title: string | any;
  children?: any;
  footer?: any;
  footerJustify?: string;
  progress: number;

  hideBackButton?: boolean;
  backCaption?: string;
  onBackClicked?: () => void;

  showCloseButton?: boolean;

  progressWidthPercentDesk?: number;
  progressWidthPercentMobile?: number;
  stickyHeader?: string;
  stickyEnabled?: boolean;
}

export function InspectorWidgetLayout({
  title,
  children,
  footer,
  progress,
  hideBackButton,
  backCaption,
  onBackClicked,
  showCloseButton,
  progressWidthPercentDesk,
  progressWidthPercentMobile,
  footerJustify,
  stickyHeader,
  stickyEnabled,
}: InspectorWidgetLayoutProps) {
  const {
    inspectorNav: { currentWidget },
    experienceState: {
      currentExperience: { status },
    },
  } = useContext(EditorContext);
  const isDesktop = useIsPreviewingDesktop();
  const pbWidth = isDesktop
    ? progressWidthPercentDesk || 60
    : progressWidthPercentMobile || 35;
  return (
    <GradientLayout
      horizontalPadding={0.001}
      paddingBottom={1}
      overflowTop={{ heightInRem: 9, turnPointInPercent: 90 }}
      overflowBottom={{ heightInRem: 5, turnPointInPercent: 50 }}
      padding={5}
      headerTop={4}
      stickyEnabled={stickyEnabled}
      header={
        <HeaderWrapper>
          <span>
            {!hideBackButton && (
              <BackEditorButton caption={backCaption} onClick={onBackClicked} />
            )}
            {showCloseButton && <CloseEditorButton />}
          </span>
          <CustomizationName className={`${stickyEnabled ? `show` : `hide`}`}>
            {stickyHeader}
          </CustomizationName>
        </HeaderWrapper>
      }
      footer={footer}
      footerJustify={footerJustify}
      contentPadding={3}
    >
      {status === `PUBLISHED` &&
        currentWidget.kind === WidgetType.CHANGELOG && (
          <PublishedBadge>Published</PublishedBadge>
        )}
      {status === `PUBLISHED` &&
        [
          WidgetType.PAGE_REDIRECT,
          WidgetType.THEME_TEST,
          WidgetType.POST_PURCHASE_EDITOR,
        ].includes(currentWidget.kind) && (
          <PublishedBadge inline>Published</PublishedBadge>
        )}
      <TitleWrapper>{title}</TitleWrapper>
      <VSpace value={2} />
      {progress === 0 ? (
        <Separator width={pbWidth} />
      ) : (
        <TinyProgressBar
          initialValue={progress}
          heightInRem={0.4}
          widthInPercent={pbWidth}
        />
      )}
      <VSpace value={3} />
      {children}
      <VSpace value={2} />
    </GradientLayout>
  );
}

const HeaderWrapper = styled.div`
  width: 100%;
  > span {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -2rem;
    user-select: none;
  }
`;

const CustomizationName = styled.div`
  text-align: left;
  font-size: 2.2rem;
  &.hide {
    opacity: 0;
    visibility: hidden;
  }
  &.show {
    opacity: 1;
    visibility: visible;
  }

  transition: all 0.5s ease;
`;

const PublishedBadge = styled.span`
  position: ${(props) => (props?.inline ? `relative` : `absolute`)};
  top: ${(props) => (props?.inline ? `-1rem` : `5rem`)};
  z-index: 29;
  font-size: 1.1rem;
  padding: 0.3rem 1rem;
  background: rgb(13, 231, 122);
  border-radius: 50px;
  color: white;
  font-weight: 600;
`;

const TitleWrapper = styled(DefaultTypography)`
  && {
    width: 100%;
    color: rgba(0, 0, 0, 0.85);
    font-family: Inter, serif;
    font-weight: 800;
    font-size: 2.5rem;
    letter-spacing: -0.11px;
    user-select: none;
  }
`;

const Separator = styled.div`
  height: 0.4rem;
  width: ${(props: { width: number }) => props.width}%;
  border-top: 0.1rem solid white;
  border-bottom: 0.1rem solid white;
  background: rgba(216, 216, 216, 0.48);
`;
