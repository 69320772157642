import { TiMinus, TiPlus } from 'react-icons/ti';
import React, { useContext } from 'react';
import styled from 'styled-components';
import { GoPrimitiveDot } from 'react-icons/go';
import { CheckboxInput } from '@/features/editor/widgets/custom-widget/inputs/shared/checkbox-input';
import {
  AdSearchContext,
  AdSearchState,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/ad-search/context';
import { FacebookTargeting } from '@/components/query-builder/models';

export function Details({
  defaultValue,
  name,
  onChange,
  status,
  onExpand,
}: {
  defaultValue: boolean;
  onChange: (value) => void;
  status: string;
  name: string;
  onExpand?: () => void;
}) {
  const { state } = useContext(AdSearchContext);

  const splitIdx = name?.toLowerCase().indexOf(state?.query?.toLowerCase());
  const start = name.slice(0, splitIdx);
  const middle = name.slice(splitIdx, splitIdx + state.query.length);
  const end = name.slice(splitIdx + state.query.length);

  return (
    <FlexRow>
      <CheckboxInput
        defaultValue={defaultValue}
        onChange={onChange}
        zIndex={32}
      />
      <GoPrimitiveDot
        style={{ marginRight: `2rem` }}
        size={20}
        color={status === `PAUSED` ? `orange` : `green`}
      />
      <Name onClick={onExpand}>
        {splitIdx < 0 ? (
          name
        ) : (
          <span>
            {start}
            <mark>{middle}</mark>
            {end}
          </span>
        )}
      </Name>
    </FlexRow>
  );
}

const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Name = styled.span`
  width: 520px;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: start;
  white-space: nowrap;
  :hover {
    cursor: pointer;
  }
  span {
    user-select: text;
  }
  user-select: text;
  color: #576470;
`;

export function ExpandBtn({
  isCollapsed,
  onClick,
}: {
  onClick: () => void;
  isCollapsed: boolean;
}) {
  return (
    <PlusBtn type="button" onClick={onClick}>
      {isCollapsed ? (
        <TiPlus color="#d8d8d8" size={20} />
      ) : (
        <TiMinus color="#d8d8d8" size={20} />
      )}
    </PlusBtn>
  );
}

const PlusBtn = styled.button`
  display: flex;
  align-items: center;

  :hover {
    opacity: 0.7;
    cursor: pointer;
  }

  :active {
    cursor: default;
  }
`;

const LoadMoreBtnContainer = styled.button`
  width: 100%;
  margin: 0.5rem auto ${(p: any) => p?.mb || `0`} auto;
  text-align: center;
  user-select: none;
  font-family: 'JetBrains Mono', serif;
  :hover {
    opacity: 0.7;
    cursor: pointer;
  }
  :active {
    opacity: 1;
    cursor: default;
  }
`;

type LoadMoreBtnProps = {
  onClick: () => void;
  loading: boolean;
  name?: string;
  mb?: string;
};

export function LoadMoreBtn({ onClick, loading, name, mb }: LoadMoreBtnProps) {
  return (
    <LoadMoreBtnContainer
      mb={mb}
      disabled={loading}
      type="button"
      onClick={onClick}
    >
      {loading ? `.........` : `load more ${name || ``}`}
    </LoadMoreBtnContainer>
  );
}

export function clone(x: any): any {
  return JSON.parse(JSON.stringify(x));
}

export function adSetHasSelectedChild(
  draft: AdSearchState,
  campaignIdx: number,
  adsetIdx: number,
) {
  return draft.campaigns?.[campaignIdx]?.adSets?.[adsetIdx]?.ads?.find(
    (x) => x.isSelected,
  );
}

export function campaignHasSelectedChild(
  draft: AdSearchState,
  campaignIdx: number,
) {
  const { adSets } = draft.campaigns[campaignIdx];
  const hasSelectedGrandSon = adSets
    ?.flatMap((x) => x?.ads?.map((x) => x.isSelected))
    ?.find((x) => x);
  const hasSelectedSon = adSets?.map((x) => x.isSelected).find((x) => x);
  return hasSelectedSon || hasSelectedGrandSon;
}

export function getFBQuery(targeting: FacebookTargeting) {
  const campaignIds = targeting.campaigns.map((c) => c.id);
  const adsets = targeting.adsets?.filter(
    (ad) => !campaignIds.includes(ad.campaign_id) && !ad.isExclude,
  );
  const ads = targeting.ads?.filter(
    (ad) =>
      !campaignIds.includes(ad.campaign_id) &&
      !adsets.map((x) => x.id).includes(ad.adSet_id) &&
      !ad.isExclude,
  );
  return {
    value: ``,
    ads,
    adsets,
    campaigns: targeting.campaigns,
  };
}
