/* eslint-disable prefer-const */
import useAsyncEffect from 'use-async-effect';
import { useEffect, useState } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

import {
  Experience,
  ExperienceVariant,
  useExperienceApi,
} from '@/webapi/use-experience-api';
import { Collection, Product, useCatalogApi } from '@/webapi/use-catalog-api';
import { nav, setQueryParam } from '@/utils/browser';
import {
  CatalogApp,
  useWidgetCatalogApi,
} from '@/webapi/use-widget-catalog-api';
import {
  ReviewsData,
  useStoreSettingsApi,
} from '@/webapi/use-store-settings-api';
import { calcAndAssignProductImageAspectRatio } from '@/features/editor/loading/aspect-ratio';
import {
  fetchAudiencesData,
  fetchEditorData,
} from '@/features/editor/loading/resource-fetch';
import { getOrCreateExperience } from '@/features/editor/loading/experience-fetch';
import {
  filterAppsCatalogSpecs,
  filterUpsellDiscounts,
} from '@/features/editor/loading/spec-filters';
import { hideLoader, showLoader } from '@/components/PageLoader';
import { Pages } from '@/webapi/pages';
import { assignTimerDates } from '@/features/editor/loading/assign-timer-dates';
import { useGetIntegrationStatus } from '@/features/integrations/context';
import { usePostPurchaseNav } from '@/features/editor/widgets/post-purchase/use-post-purchase-nav';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { useShopifyApi } from '@/webapi/use-shopify-api';
import { Theme } from '@/webapi/models';
import { maybe } from '@/features/details/utils';
import { StoreStyles } from '@/features/editor/widgets/custom-widget/style-templating/models';
import { Audience } from '@/features/dashboard/audiences/models';
import { rtlSettings } from '@/features/editor/loading/rtlSettings';

export function useEditorLoader() {
  const [expId] = useQueryParam(`expId`, StringParam);
  const [postPurchase] = useQueryParam(`post_purchase`, StringParam);

  const {
    getExperience,
    upsertExperience,
    listAudiences,
    loading: expLoading,
    error,
  } = useExperienceApi();

  const { listEntireCatalog, loading: catalogLoading } = useCatalogApi();
  const { getAllApps } = useWidgetCatalogApi(true);
  const { getActivePostPurchaseExperienceId, loading: postPurchaseLoading } =
    usePostPurchaseNav();
  const { listReviewsProviders, isShopRTL, setIsShopRTL } =
    useStoreSettingsApi();
  const { getIntegrationStatus, loading: integrationsLoading } =
    useGetIntegrationStatus();
  const isColorSwatchEnabled = useFeatureBit(
    FeatureBit.SHOW_COLORS_SWATCH,
    false,
  );
  const isUpsellDiscountsEnabled = useFeatureBit(
    FeatureBit.ENABLE_UPSELLS_DISCOUNTS,
    false,
  );
  const isFetchEntireCatalogAllowed = useFeatureBit(
    FeatureBit.FETCH_ENTIRE_CATALOG,
    false,
  );
  const { listThemes, loading: shopifyLoading } = useShopifyApi(true);
  useEffect(() => {
    expLoading ? showLoader() : hideLoader();
  }, [expLoading]);

  const [reloadAll, setReloadAll] = useState(false);
  const [resources, setResources] = useState<LoadedEditorResources>(undefined);
  const reloadResources = () => setReloadAll(!reloadAll);
  useEffect(() => {
    document.addEventListener(`SelectReviewsProvider`, reloadResources);
    return () =>
      document.removeEventListener(`SelectReviewsProvider`, reloadResources);
  }, []);

  useAsyncEffect(
    async (isActive) => {
      if (error) {
        isActive() && nav(Pages.DASHBOARD);
      }
    },
    [error],
  );

  useAsyncEffect(
    async (isActive) => {
      const { experience, control } = await getOrCreateExperience(
        expId,
        getExperience,
        upsertExperience,
        !!postPurchase,
      );

      let {
        collections,
        products,
        tags,
        appsCatalog,
        reviews,
        customerTags,
        currencies,
        postPurchaseStatus,
        integrationsStatus,
        themes,
        mainAttributeOptions,
        mainAttribute,
        storeStyle,
        enabledOnMultipleThemes,
        audiences,
        isShopRtl,
      } = await fetchEditorData(
        listEntireCatalog,
        getAllApps,
        listReviewsProviders,
        getIntegrationStatus,
        getActivePostPurchaseExperienceId,
        listThemes,
        listAudiences,
        isFetchEntireCatalogAllowed,
        isShopRTL,
      );

      const productTileAspectRatio = calcAndAssignProductImageAspectRatio(
        products,
        appsCatalog,
      );
      rtlSettings(appsCatalog, experience, isShopRtl, setIsShopRTL);
      appsCatalog = filterAppsCatalogSpecs(appsCatalog, {
        reviews,
        isColorSwatchEnabled,
      });
      appsCatalog = assignTimerDates(appsCatalog);
      if (!isUpsellDiscountsEnabled) {
        appsCatalog = filterUpsellDiscounts(appsCatalog);
      }

      if (!isActive()) {
        return;
      }

      setResources({
        enabledOnMultipleThemes,
        storeStyle,
        mainAttribute,
        mainAttributeOptions,
        themes,
        experience,
        collections,
        products,
        appsCatalog,
        productTileAspectRatio,
        tags,
        customerTags,
        reviews,
        currencies,
        audiences,
        control,
        hasActivePostPurchase: postPurchaseStatus,
        gaIntegrationEnabled:
          integrationsStatus?.googleAnalytics === `YES` ||
          integrationsStatus?.googleAnalytics === `UNSET`,
        klavioIntegrationEnabled:
          integrationsStatus?.klavio === `YES` ||
          integrationsStatus?.klavio === `UNSET`,
      });
      !!experience && setQueryParam(`expId`, experience.id);
    },
    [reloadAll],
  );

  return {
    loading:
      expLoading ||
      catalogLoading ||
      integrationsLoading ||
      postPurchaseLoading ||
      shopifyLoading,
    resources,
  };
}

export function useAudiencesLoader() {
  const { listEntireCatalog, loading: catalogLoading } = useCatalogApi();

  const [resources, setResources] = useState<any>(undefined);

  useAsyncEffect(async (isActive) => {
    let {
      collections,
      products,
      tags,
      customerTags,
      currencies,
      mainAttributeOptions,
      mainAttribute,
    } = await fetchAudiencesData(listEntireCatalog);

    if (!isActive()) {
      return;
    }

    setResources({
      mainAttribute,
      mainAttributeOptions,
      collections,
      products,
      tags,
      customerTags,
      currencies,
    });
  }, []);

  return {
    resources,
    loading: catalogLoading,
  };
}

export interface LoadedEditorResources {
  hasActivePostPurchase?: boolean;
  gaIntegrationEnabled?: boolean;
  klavioIntegrationEnabled?: boolean;
  experience: Experience;
  themes: Array<Theme>;
  collections: Collection[];
  products: Product[];
  appsCatalog: CatalogApp[];
  tags: Array<string>;
  customerTags?: Array<string>;
  productTileAspectRatio: number;
  reviews: ReviewsData;
  currencies: Array<string>;
  audiences: Array<Audience>;
  mainAttribute: string;
  mainAttributeOptions: Array<string>;
  storeStyle?: StoreStyles;
  enabledOnMultipleThemes?: boolean;
  control: ExperienceVariant;
}

export function hasVariantPrimaryAttrName(resources: LoadedEditorResources) {
  return maybe(() =>
    resources.products
      ?.flatMap((p) => p?.options?.map((o) => o?.name?.toLowerCase()))
      .includes(resources?.mainAttribute?.toLowerCase()),
  );
}

export function hasAllVariantPrimaryAttrOptions(
  resources: LoadedEditorResources,
) {
  const options = resources?.products?.flatMap((p) =>
    p?.options?.flatMap((o) => o?.values?.map((v) => v?.toLowerCase())),
  );

  return (
    hasVariantPrimaryAttrName(resources) ||
    resources?.mainAttributeOptions
      ?.map((o) => options?.includes(o?.toLowerCase()))
      ?.reduce((p, c) => p && c, true)
  );
}
