/* eslint-disable class-methods-use-this,@typescript-eslint/ban-ts-comment,func-names,guard-for-in,no-restricted-syntax,prefer-rest-params,no-empty */
import React from 'react';
import { QueryParamProvider } from 'use-query-params';
import { navigate } from 'gatsby';
import { toast, ToastContainer } from 'react-toastify';
import { css } from 'styled-components';
import hash from 'object-hash';
import Helmet from 'react-helmet';
import { enablePatches } from 'immer';
import LogRocket from 'logrocket';
import { Layout } from './components/layout';
import { GlobalStyles } from './styles/globalStyles';
import { ACCOUNT_DETAILS_KEY, isPrivatePath } from '@/features/account-context';
import { routes } from '@/webapi/routes';
import { Pages } from '@/webapi/pages';
import 'react-toastify/dist/ReactToastify.css';
import { hideLoader, Loader, showLoader } from '@/components/PageLoader';
import {
  getAliasQueryParam,
  setHttpsPrefix,
  setQueryParam,
} from '@/utils/browser';
import { StoreDetails } from '@/webapi/use-auth-api';
import './fonts.css';

function generatePath(location) {
  return location.pathname + location.search;
}

const history = {
  push: (location) => {
    navigate(generatePath(location), {
      replace: true,
      state: location.state,
    });
  },
  replace: (location) => {
    navigate(generatePath(location), {
      replace: true,
      state: location.state,
    });
  },
};

export const onRouteUpdateDelayed = () => {
  showLoader();
};

export const onRouteUpdate = () => {
  hideLoader();
};

export const wrapRootElement = ({ element }) => (
  <>
    <Helmet>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, maximum-scale=1"
      />
      <meta
        name="description"
        content="No-code A/B test, optimization, and personalization through a next-generation visual editor."
      />
      <title>Visually.io | No-code CRO for Shopify</title>
      <link rel="canonical" href="https://www.visually.io/" />
      <meta
        property="og:image"
        content="https://visually-sdk.b-cdn.net/VSLY/social_share.png"
      />
    </Helmet>
    <Loader />
    <ToastContainer
      style={{
        fontFamily: `JetBrains Mono, Serif`,
        fontWeight: `500`,
        fontSize: `18px`,
      }}
    />
    {element}
  </>
);

export const wrapPageElement = ({ element, props }) => (
  <QueryParamProvider history={history} location={props.location}>
    <Layout location={props.location}>
      {props.location.pathname !== `/` && <GlobalStyles />}
      {element}
    </Layout>
  </QueryParamProvider>
);
const cacheWhitelist = [
  `/catalog/web/v1/recommendation/products/list`,
  `/catalog/web/v1/list`,
  `/catalog/web/v1/list/paginate`,
  `/statistics/v1/experiences/products`,
  `/statistics/v2/store/summary/experiences`,
  `/statistics/v1/store/summary/products`,
  `/statistics/v1/store/summary/labels`,
  `/web/v1/labels/experience`,
  `/statistics/v2/store/summary/experiences`,
  `/apps-catalog/web/v1/apps/widget`,
  `/apps-catalog/web/v1/apps/list`,
  `/store-settings/web/v1/fb/campaigns`,
  `/store-settings/web/v1/fb/images`,
  `/analytics/statistics/v1/store/summary`,
  `/analytics/statistics/v1/store/summary/experiences`,
  `/experience/web/v1/experience/names`,
];
let cache: Map<string, { response: Response; date: Date }>;

function setCache(requestHash, response: Response) {
  cache[requestHash] = {
    response,
    date: new Date(),
  };
}

function shouldUseCache(resource, requestHash) {
  const inWhitelist = cacheWhitelist.find((x) => !!resource?.endsWith(x));
  const cached = cache?.[requestHash];
  const cacheHit = inWhitelist && isFresh(cached?.date) && !!cached?.response;
  return { cached, cacheHit, inWhitelist };
}

function getUserDetails(): StoreDetails {
  try {
    return JSON.parse(
      window?.localStorage?.getItem(ACCOUNT_DETAILS_KEY) || `{}`,
    )?.store;
  } catch (e) {
    return {} as StoreDetails;
  }
}

function hasUser(store) {
  return !!store?.alias && !!store?.email;
}

function shouldReLogin(store: StoreDetails, url) {
  const aliasQueryParam = getAliasQueryParam();
  const aliasLocalStorage = store?.alias;
  const shouldReLogIn =
    !!aliasQueryParam &&
    !!aliasLocalStorage &&
    !!window?.parallelTabs &&
    aliasQueryParam !== aliasLocalStorage &&
    !url?.includes(`/accounts/web/v1/user/context`);
  return { aliasQueryParam, shouldReLogIn };
}

async function implicitLogin(
  url,
  originalFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
  store: StoreDetails,
) {
  const { aliasQueryParam, shouldReLogIn } = shouldReLogin(store, url);
  if (shouldReLogIn) {
    const response = await originalFetch(
      `${routes.userContext()}?value=${aliasQueryParam}`,
    );
    if (!response?.ok) {
      toast(`User has no access to store ${aliasQueryParam}`, {
        theme: `colored`,
        type: `error`,
        className: css({ fontFamily: `JetBrains Mono, Serif` }),
      });
      throw new Error(`User has no access to store ${aliasQueryParam}`);
    }
    const resp = await response.json();
    resp.domain = setHttpsPrefix(resp.domain);
    window.localStorage.setItem(
      ACCOUNT_DETAILS_KEY,
      JSON.stringify({ isLoggedIn: true, store: resp }),
    );
    window[`vsly_fbs`] = resp.featureBits;
    setQueryParam(`al`, resp.alias);
  }
}

function isFreshInstall(store: StoreDetails) {
  try {
    const ONE_HOUR = 60 * 60 * 1000;
    // @ts-ignore
    return new Date() - new Date(store.userCreatedAt) < ONE_HOUR * 2;
  } catch (e) {}
  return false;
}

async function forceLogout(
  originalFetch: (input: RequestInfo, init?: RequestInit) => Promise<Response>,
) {
  window?.localStorage.clear();
  try {
    await originalFetch(routes.logout());
  } catch (e) {
    console.log(e, `logout route failure`);
  }

  window.location.replace(Pages.LOGIN);
}

function notInWhitelist(url: any) {
  return !url.includes(`accounts/web/v1/billing/tiers/change`);
}

function monkeyPatchFetch() {
  cache = new Map();
  const originalFetch = window?.fetch;
  window.fetch = async (...args) => {
    const [url, config] = args as any;
    const store = getUserDetails() as StoreDetails;
    if (isSameSiteApiCall(url) && hasUser(store) && notInWhitelist(url)) {
      let response: Response;
      try {
        const requestHash = hash({
          resource: url,
          alias: store?.alias,
          email: store?.email,
          method: config?.method || `GET`,
          ...JSON.parse(config?.body || `{}`),
        });

        const { cached, cacheHit, inWhitelist } = shouldUseCache(
          url,
          requestHash,
        );
        const { shouldReLogIn: invalidLogin } = shouldReLogin(store, url);
        if (cacheHit && !invalidLogin) {
          return cached?.response;
        }
        await implicitLogin(url, originalFetch, store);
        response = await originalFetch(url, config);
        if (inWhitelist) {
          setCache(requestHash, response);
        }

        if (isFreshInstall(store)) {
          return response;
        }

        if ([401, 403].includes(response.status)) {
          await forceLogout(originalFetch);
        } else if (!response.ok) {
          const errJson = await response.json().catch(() => ({}));
          toast(transformError(errJson), {
            theme: `colored`,
            type: `error`,
            className: css({ fontFamily: `JetBrains Mono, Serif` }),
          });
        }
        return response;
      } catch (ex) {
        return originalFetch(url, config);
      }
    } else if (
      isSameSiteApiCall(url) &&
      !hasUser(store) &&
      isPrivatePath(window.location.pathname)
    ) {
      const response = await originalFetch(url, config);
      if ([401, 403].includes(response.status)) {
        await forceLogout(originalFetch);
      }
      return response;
    }
    return originalFetch(url, config);
  };
}

function monkeyPatchLocalStorage() {
  function prefixedKey(k: string) {
    const aliasQueryParam = getAliasQueryParam();
    return `${aliasQueryParam}_${k}`;
  }

  const nativeLocalStorage = window.localStorage;
  // @ts-ignore
  window.nativeLocalStorage = nativeLocalStorage; // keep the original usage

  class MyLocalStorage {
    setItem(k, v) {
      if (!window?.parallelTabs) {
        nativeLocalStorage.setItem(k, v);
        return;
      }
      if (k === ACCOUNT_DETAILS_KEY) {
        nativeLocalStorage.setItem(k, v);
        return;
      }
      nativeLocalStorage.setItem(prefixedKey(k), v);
    }

    getItem(k) {
      if (!window?.parallelTabs) {
        return nativeLocalStorage.getItem(k);
      }
      if (k === ACCOUNT_DETAILS_KEY) {
        return nativeLocalStorage.getItem(k);
      }
      return (
        nativeLocalStorage.getItem(prefixedKey(k)) ||
        nativeLocalStorage.getItem(k)
      );
    }

    removeItem(k) {
      if (!window?.parallelTabs) {
        nativeLocalStorage.removeItem(k);
        return;
      }
      if (k === ACCOUNT_DETAILS_KEY) {
        nativeLocalStorage.removeItem(k);
        return;
      }
      nativeLocalStorage.removeItem(prefixedKey(k));
      nativeLocalStorage.removeItem(k);
    }
  }

  const myLocalStorage = new MyLocalStorage();

  const proxyStorage = new Proxy(nativeLocalStorage, {
    get(target: Storage, p: string): any {
      if ([`getItem`, `setItem`, `removeItem`]?.includes(p)) {
        return myLocalStorage[p];
      }
      // @ts-ignore
      const value = target[p];
      if (value instanceof Function) {
        return function (...args) {
          return value.apply(target, args);
        };
      }
      return value;
    },
  });
  Object.defineProperty(window, `localStorage`, {
    value: proxyStorage,
    writable: true,
  });
}

export const onClientEntry = () => {
  enablePatches();
  try {
    window.parallelTabs = localStorage.getItem(`parallelTabs`) === `true`;
  } catch (e) {
    console.log(e);
  }
  monkeyPatchFetch();
  monkeyPatchLocalStorage();
  setupLogRocket();
};

function setupLogRocket() {
  try {
    LogRocket.init(`jhuttz/visuallyio`, {
      mergeIframes: true,
      childDomains: [
        `https://visually.io`,
        `https://sdk.loomi-prod.xyz`,
        `https://editor.loomi-prod.xyz`,
      ],
      parentDomain: `https://visually.io`,
    });
  } catch (ex) {
    console.error(`LogRocket init failed`, ex);
  }
}

function isSameSiteApiCall(resource) {
  if (typeof resource !== `string`) {
    return false;
  }
  return (
    resource.startsWith(`/stage/lmiapi/`) || resource.startsWith(`/lmiapi/`)
  );
}

function isFresh(date: Date): boolean {
  if (!date) {
    return false;
  }
  return date?.valueOf() > Date.now() - 5 * 60 * 1000;
}

function transformError(x: any) {
  try {
    if (x?.message?.toString()?.includes(`privileges`)) {
      return x?.message?.replace(`privileges`, `privileges : `);
    }
    if (x?.message?.toString()?.includes(`(#17) User request limit reached`)) {
      return `Facebook request limit reached - please try again in a few minutes`;
    }
    return `Oops.. something went wrong...`;
  } catch (e) {
    return `Oops.. something went wrong...`;
  }
}
