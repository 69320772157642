import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import {
  AudienceContext,
  View,
} from '@/features/editor/widgets/shared/modals/audience/audience-context';
import {
  CustomAudienceBtn,
  CustomQB,
} from '@/features/editor/widgets/shared/modals/audience/custom-audience';
import { LineItem } from '@/features/editor/widgets/shared/modals/audience/audience-shared';
import { FacebookLineItem } from '@/features/editor/widgets/shared/modals/audience/facebook-audience/line-item';
import { BackModalHeader } from '@/features/editor/widgets/shared/modals/commons';
import {
  FacebookContainer,
  FacebookHeader,
} from '@/features/editor/widgets/shared/modals/audience/facebook-audience/container';
import { EditorContext } from '@/features/editor/context/editor-context';
import { PlacementKind, Segment, Targeting } from '@/webapi/use-experience-api';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import { AudienceDescriptionForm } from '@/features/dashboard/audiences/name-form';
import { SelectorSection } from '@/features/editor/widgets/changelog/tile';

export function AudienceModalBody() {
  const {
    view,
    userDefinedAudienceName: name,
    setUserDefinedAudienceName: setName,
    userDefinedAudienceDesc: description,
    setUserDefinedAudienceDesc: setDescription,
  } = useContext(AudienceContext);
  switch (view) {
    case View.AUDIENCE_NAME:
      return (
        <AudienceDescriptionForm
          value={name}
          onChange={(ev) => setName(ev.target.value)}
          description={description}
          setDescription={(ev) => setDescription(ev.target.value)}
        />
      );
    case View.CUSTOM:
      return <CustomQB />;
    case View.FACEBOOK:
      return <FacebookContainer />;
    default:
      return <AudienceSelection />;
  }
}

export function AudienceModalHeader() {
  const {
    view,
    onBackToCustom,
    onBackToSelection,
    userDefinedAudienceId,
    onNameAudience,
  } = useContext(AudienceContext);
  const isEnabled = useFeatureBit(FeatureBit.CUSTOM_AUDIENCES_TAB);
  switch (view) {
    case View.AUDIENCE_NAME:
      return (
        <BackModalHeader
          back={onBackToCustom}
          header={
            <CustomHeaderWrapper>
              <span>Set a name for the audience</span>
            </CustomHeaderWrapper>
          }
        />
      );
    case View.CUSTOM:
      return (
        <BackModalHeader
          back={onBackToSelection}
          header={
            <CustomHeaderWrapper>
              <span>Build a Custom Audience</span>
              {isEnabled && (
                <AudienceWrapperButton onClick={onNameAudience}>
                  {userDefinedAudienceId ? `Update Audience` : `Save Audience`}
                </AudienceWrapperButton>
              )}
            </CustomHeaderWrapper>
          }
        />
      );
    case View.FACEBOOK:
      return <FacebookHeader back={onBackToSelection} />;
    default:
      return <>Choose the audience that will see the experience</>;
  }
}
const CustomHeaderWrapper = styled.div`
  position: relative;
`;

const AudienceWrapperButton = styled.button`
  && {
    padding: 0.5rem 1rem;
    border-radius: 8px;
    color: #91948b;
    background-color: #efefef;
    position: absolute;
    top: 0;
    left: 35rem;
    width: 135px;
  }
`;

function AudienceSelection() {
  const {
    resources,
    newAudiences,
    experienceState: {
      currentExperience: { isPostPurchase, targeting },
    },
  } = useContext(EditorContext);
  const {
    onPredefined,
    onUserDefined,
    onCustom,
    selected,
    audiences: segments,
    onAllVisitors,
    userDefinedAudienceId,
    view,
    isCheckout,
  } = useContext(AudienceContext);
  const isUserDefinedAudiencesEnabled = isAudienceEnabled(
    isPostPurchase,
    targeting,
  );
  const userDefinedAudiences = isUserDefinedAudiencesEnabled
    ? [...(resources?.audiences || []), ...newAudiences]
    : [];
  const isAllVisitorsSelected =
    !(view === View.CUSTOM) && selected.length === 0 && !userDefinedAudienceId;

  function renderText(text, editorId) {
    const threshold = 100;
    return text.length > threshold ? (
      <SelectorSection
        selector={text}
        editorId={editorId}
        image=""
        limit={threshold}
      />
    ) : (
      <span>{text}</span>
    );
  }
  return (
    <>
      <CustomAudienceBtn onClicked={onCustom} />
      {userDefinedAudiences.length > 0 ? (
        <>
          <span>Or choose from these primary audiences</span>
          <Grid>
            {userDefinedAudiences?.map((userDefinedAudience) => (
              <LineItem
                disabled={isPostPurchase}
                key={userDefinedAudience.id}
                selected={userDefinedAudienceId === userDefinedAudience.id}
                onClicked={(_, value) =>
                  onUserDefined(value, userDefinedAudience)
                }
              >
                <span
                  style={{
                    maxWidth: `100%`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                  }}
                >
                  {userDefinedAudience.name}
                </span>
                <span
                  style={{
                    maxWidth: `100%`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                  }}
                >
                  {userDefinedAudience.description}
                </span>
              </LineItem>
            ))}
          </Grid>
        </>
      ) : null}
      <>
        <span>Or choose from these predefined audiences</span>
        <Grid>
          <LineItem
            key="all"
            selected={isAllVisitorsSelected}
            onClicked={onAllVisitors}
          >
            <span>All Users</span>
            <span>Everyone that browses your store</span>
          </LineItem>
          <FacebookLineItem disabled={isPostPurchase} />
          {segments
            .filter((segment) =>
              isCheckout ? segment.kind !== Segment.GUESTS : true,
            )
            .map((segment) => (
              <LineItem
                disabled={isPostPurchase}
                key={segment.kind}
                selected={!!selected.find((s) => s === segment.kind)}
                onClicked={(_, value) => onPredefined(value, segment.kind)}
              >
                <span
                  style={{
                    maxWidth: `100%`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                    paddingBottom: `3px`,
                  }}
                >
                  {segment.name}
                </span>
                <span
                  style={{
                    maxWidth: `100%`,
                    overflow: `hidden`,
                    textOverflow: `ellipsis`,
                  }}
                >
                  {segment.description}
                </span>
              </LineItem>
            ))}
        </Grid>
      </>
      {resources?.customerTags?.length > 0 && !isPostPurchase ? (
        <>
          <span>Shopify Customer Tags</span>
          <Grid>
            {resources?.customerTags?.map((v) => (
              <LineItem
                key={v}
                selected={!!selected.find((s) => s === `CUSTOMER_TAG__${v}`)}
                onClicked={(_, value) =>
                  onPredefined(
                    value,
                    `CUSTOMER_TAG__${v}` as unknown as Segment,
                  )
                }
              >
                {renderText(v, `customer-tag-${v}`)}
                <Spacer />
                {renderText(
                  `Logged in customers tagged with ${v}`,
                  `customer-tag-description-${v}`,
                )}
              </LineItem>
            ))}
          </Grid>
        </>
      ) : null}
    </>
  );
}

const Spacer = styled.div`
  height: 6px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 2.5rem;
`;

function isAudienceEnabled(isPostPurchase: boolean, targeting: Targeting) {
  return (
    useFeatureBit(FeatureBit.CUSTOM_AUDIENCES_TAB) &&
    !isPostPurchase &&
    targeting?.placement?.kind !== PlacementKind.CHECKOUT
  );
}
