import styled from 'styled-components';
import { breakpoints } from '@/components/responsive';

export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${({ direction }: FlexboxProps) => direction || `column`};
  flex: ${({ flex }: FlexboxProps) => flex || `unset`};
  align-items: ${({ align }: FlexboxProps) => align || `flex-start`};
  justify-content: ${({ justify }: FlexboxProps) => justify || `flex-start`};
  ${({ self }: FlexboxProps) => (self ? `align-self: ${self};` : ``)}
  ${({ width }: FlexboxProps) => (width ? `width: ${width};` : ``)}
  ${({ height }: FlexboxProps) => (height ? `height: ${height};` : ``)}
  ${({ padding }: FlexboxProps) => (padding ? `padding: ${padding};` : ``)}
  ${(props: FlexboxProps) => (props.gap ? `gap: ${props.gap}` : ``)}
  ${({ overflow }: FlexboxProps) => (overflow ? `overflow: ${overflow}` : ``)};

  ${breakpoints.down(`md`)} {
    flex-direction: ${({ direction, mbDirection }: FlexboxProps) =>
      mbDirection || direction || `column`};
    flex: ${({ flex, mbFlex }: FlexboxProps) => mbFlex || flex || `unset`};
    align-items: ${({ align, mbAlign }: FlexboxProps) =>
      mbAlign || align || `flex-start`};
    justify-content: ${({ justify, mbJustify }: FlexboxProps) =>
      mbJustify || justify || `flex-start`};
    ${({ mbSelf }: FlexboxProps) => (mbSelf ? `align-self: ${mbSelf};` : ``)}
    ${({ mbWidth }: FlexboxProps) => (mbWidth ? `width: ${mbWidth};` : ``)}
    ${({ mbHeight }: FlexboxProps) => (mbHeight ? `height: ${mbHeight};` : ``)}
    ${({ mbPadding }: FlexboxProps) =>
      mbPadding ? `padding: ${mbPadding};` : ``}
    ${(props: FlexboxProps) => (props.mbGap ? `gap: ${props.mbGap}` : ``)}
    ${({ mbOverflow }: FlexboxProps) =>
      mbOverflow ? `overflow: ${mbOverflow}` : ``};
  }
`;

// const gap = (props: FlexboxProps): string => {
//   if ([`column`, `column-reverse`].includes(props.direction)) {
//     return `
//     && > :not(:last-child) {
//       margin-bottom: ${props.gap};
//     `;
//   }
//   return `
//     && > :not(:last-child) {
//       margin-right: ${props.gap};
//     `;
// };

export interface FlexboxProps {
  overflow?: `hidden` | `auto` | `scroll`;
  direction?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  flex?: number;
  width?: string;
  height?: string;
  padding: string;
  align?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  self?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  gap?: string;

  mbOverflow?: `hidden` | `auto` | `scroll`;
  mbDirection?: 'column' | 'column-reverse' | 'row' | 'row-reverse';
  mbFlex?: number;
  mbWidth?: string;
  mbHeight?: string;
  mbPadding?: string;
  mbAlign?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  mbSelf?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  mbJustify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around';
  mbGap?: string;
}

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`;
