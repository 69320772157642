import { CachePolicies, useFetch } from 'use-http';
import useAsyncEffect from 'use-async-effect';
import React from 'react';
import { routes } from '@/webapi/routes';
import { useComplexState } from '@/utils/use-complex-state';

type STATUS = 'YES' | 'NO' | 'UNSET';

export type IntegrationsStatus = {
  klavio: STATUS;
  googleAnalytics: STATUS;
  facebook: STATUS;
  instagram: STATUS;

  trackingIntegrations?: {
    amplitude: STATUS;
    clarity: STATUS;
    contentsquare: STATUS;
    fullstory: STATUS;
    heatmap: STATUS;
    hotjar: STATUS;
    segment: STATUS;
  };
};

export const IntegrationsContext = React.createContext(
  {} as IntegrationsContext,
);

type IntegrationsContext = {
  toggleGoogleAnalytics: () => Promise<void>;
  loading: boolean;
  toggleKlavio: () => Promise<void>;
  toggleFacebook: () => Promise<void>;
  toggleInstagram: () => Promise<void>;
  toggleTrackingIntegration: (integration: string) => Promise<void>;
  integrationsStatus: IntegrationsStatus;
};

export function useGetIntegrationStatus(initialLoading?: boolean) {
  const { get, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  const getIntegrationStatus = async () =>
    (await get(routes.integrations)) as IntegrationsStatus;
  return {
    loading,
    getIntegrationStatus,
  };
}

export function newIntegrationsContext(
  initialLoading?: boolean,
): IntegrationsContext {
  const { get, put, loading } = useFetch(``, (globalOptions) => ({
    ...globalOptions,
    ...{
      credentials: `include`,
      cachePolicy: CachePolicies.NO_CACHE,
      loading: initialLoading,
    },
  }));

  const [integrationsStatus, setIntegrationsStatus] =
    useComplexState<IntegrationsStatus>({} as IntegrationsStatus);

  const setStatus = async () => {
    const resp = (await get(routes.integrations)) as IntegrationsStatus;
    setIntegrationsStatus((draft) => {
      draft.klavio = resp?.klavio || `UNSET`;
      draft.facebook = resp?.facebook || `UNSET`;
      draft.googleAnalytics = resp?.googleAnalytics || `UNSET`;
      draft.instagram = resp?.instagram || `UNSET`;
      draft.trackingIntegrations = resp?.trackingIntegrations;
    });
  };

  useAsyncEffect(async () => {
    await setStatus();
  }, []);

  const toggleKlavio = async () => {
    const value = integrationsStatus?.klavio === `YES` ? `NO` : `YES`;
    setIntegrationsStatus((draft) => {
      draft.klavio = value;
    });
    await put(routes.integrations, {
      klavio: value,
    });
  };

  const toggleGoogleAnalytics = async () => {
    const value = integrationsStatus?.googleAnalytics === `YES` ? `NO` : `YES`;
    setIntegrationsStatus((draft) => {
      draft.googleAnalytics = value;
    });
    await put(routes.integrations, {
      googleAnalytics: value,
    });
  };

  const toggleFacebook = async () => {
    const value = integrationsStatus?.facebook === `YES` ? `NO` : `YES`;
    setIntegrationsStatus((draft) => {
      draft.facebook = value;
    });
    await put(routes.integrations, {
      facebook: value,
    });
  };

  const toggleInstagram = async () => {
    const value = integrationsStatus?.instagram === `YES` ? `NO` : `YES`;
    setIntegrationsStatus((draft) => {
      draft.instagram = value;
    });
    await put(routes.integrations, {
      instagram: value,
    });
  };

  const toggleTrackingIntegration = async (integration: string) => {
    const value =
      integrationsStatus?.trackingIntegrations?.[integration] === `YES`
        ? `NO`
        : `YES`;
    setIntegrationsStatus((draft) => {
      draft.trackingIntegrations = {
        ...draft.trackingIntegrations,
        [integration]: value,
      };
    });
    const integrations = await put(routes.integrations, {
      trackingIntegrations: {
        ...integrationsStatus.trackingIntegrations,
        [integration]: value,
      },
    });
    setIntegrationsStatus((draft) => {
      draft.trackingIntegrations = integrations?.trackingIntegrations;
    });
  };

  return {
    loading,
    toggleTrackingIntegration,
    integrationsStatus,
    toggleInstagram,
    toggleKlavio,
    toggleGoogleAnalytics,
    toggleFacebook,
  };
}
