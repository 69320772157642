import * as React from 'react';
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';
import { VscChevronDown } from 'react-icons/vsc';

import { useContext } from 'react';
import { InspectorWidgetLayout } from '@/features/editor/widgets/shared/layout';
import {
  asContentKind,
  ContentKindSelect,
} from '@/features/editor/widgets/shared/content-kind-select';
import { DeviceType } from '@/utils/definitions';
import { TextInput } from '@/components/text-input';
import { BigButton } from '@/components/big-button';
import { useLocationPicker } from '@/features/editor/widgets/shared/location-picker/use-location-picker';
import { StickyBigArrow } from '@/components/big-arrow';
import { useObservableRef } from '@/components/hooks/use-observeable-ref';
import { AccountContext } from '@/features/account-context';
import { UserStatsKind } from '@/webapi/use-user-stats';
import { VSpace } from '@/components/spacing';
import { AllMutationKind } from '@/pkg/sdk';

export interface LocationPickerProps {
  title: string;
  description?: string;
  onBack: () => void;
  backCaption: string;
  onContinue: (
    selector: string,
    placement: AllMutationKind,
    isInCart?: boolean,
  ) => void;
  onLocationChanged?: (selector: string, placement: AllMutationKind) => void;
}

export function LocationPicker({
  title,
  description,
  onBack,
  backCaption,
  onContinue,
  onLocationChanged,
}: LocationPickerProps) {
  const {
    isDesktop,
    isPicked,
    location,
    onRelocate,
    isManual,
    toggleManual,
    onContentKindChanged,
    onManualSelectorChanged,
    isEnabled,
    onBackClicked,
    id,
  } = useLocationPicker(onBack, onLocationChanged);

  const { isGoalReached, trackIfGoalNotReached } = useContext(AccountContext);

  trackIfGoalNotReached(
    UserStatsKind.SHOULD_SHOW_LOCATION_PICKER_ONBOARDING,
    id,
  ).then();

  const isOnboardShown = !isGoalReached(
    UserStatsKind.SHOULD_SHOW_LOCATION_PICKER_ONBOARDING,
  );

  const observableRef = useObservableRef(null);
  const [, refSetter] = observableRef;

  return (
    <InspectorWidgetLayout
      title={title}
      progress={40}
      onBackClicked={onBackClicked}
      backCaption={backCaption}
    >
      <Wrapper ref={refSetter} isDesk={isDesktop}>
        {isOnboardShown && (
          <StickyBigArrow
            hideAfter={5000}
            observableRef={observableRef}
            topOffset={64}
            leftOffset={-120}
          />
        )}
        {isPicked ? (
          <SelectorWrapper>
            <span>The location was successfully selected</span>
            <Selector isDesk={isDesktop}>
              {kindToText(location?.placement)}
              {` `}
              {location?.selector}
            </Selector>
            <RelocateButton onClick={onRelocate}>Relocate</RelocateButton>
          </SelectorWrapper>
        ) : (
          <Hint>
            <PointerIcon />
            <span>
              {description ||
                `To add the new section, navigate to the desired location on the
              left. Once you\`ve found the spot, click the pink plus button to
              place it there.`}
            </span>
          </Hint>
        )}

        <ManualSelector isManual={isManual} onClick={toggleManual}>
          Alternatively, you can <u>manually enter a CSS selector</u>
          {` `}
          <VscChevronDown size={20} />
        </ManualSelector>

        {isManual && (
          <SelectorWrapper>
            <span>
              Choose this option if you are familiar with CSS and want to
              specify a CSS selector to set the location of the block
            </span>
            <InputWrapper>
              <ContentKindSelect
                device={DeviceType.Mobile}
                defaultValue={asContentKind(location?.placement)}
                onSelection={onContentKindChanged}
              />
              <StyledInput
                placeholder="CSS SELECTOR"
                defaultValue={location?.selector || ``}
                onChange={onManualSelectorChanged}
              />
            </InputWrapper>
          </SelectorWrapper>
        )}
        <VSpace value={2} />
        <StyledBigButton
          disabled={!isEnabled}
          onClick={() => {
            onContinue(
              location?.selector,
              location?.placement,
              location.isInCart,
            );
          }}
          border="2px solid black"
          noTransform
          size="medium"
        >
          Continue
        </StyledBigButton>
      </Wrapper>
    </InspectorWidgetLayout>
  );
}

interface WP {
  isDesk: boolean;
}

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-items: flex-start;
  justify-content: flex-start;
  grid-gap: 3rem;
  font-size: ${(p: WP) => (p.isDesk ? `1.3rem` : `1.5rem`)};
  font-family: Inter, serif;
  width: ${(p: WP) => (p.isDesk ? `100%` : `65%`)};
  overflow-x: hidden;
`;

const Hint = styled.div`
  width: 100%;
  user-select: none;
  background: #f8f9fb;
  border-radius: 1rem;
  display: grid;
  grid-template-columns: max-content 1fr;
  grid-gap: 3rem;
  align-items: center;
  justify-content: center;

  font-family: Inter, serif;
  font-weight: normal;
  color: #475466;
  text-align: start;
  line-height: 3rem;

  padding: 4rem 3rem;
`;

const Selector = styled.span`
  user-select: auto;
  font-family: 'JetBrains Mono', serif;
  font-size: ${(p: WP) => (p.isDesk ? `1.3rem` : `1.4rem`)};
`;

const RelocateButton = styled.button`
  user-select: none;
  outline: none;
  appearance: none;
  margin: 0;
  padding: 0;
  width: fit-content;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 1.2rem;

  font-family: 'JetBrains Mono', Serif;

  background: transparent;
  color: #475466;
  text-decoration: underline;
  border-radius: 2rem;

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;

interface MSP {
  isManual: boolean;
}

const InputWrapper = styled.div`
  && {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    grid-gap: 1.5rem;
  }
`;

const StyledInput = styled(TextInput)`
  && {
    border-radius: 2rem;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 0.8rem 1.5rem;
    border-color: rgba(144, 149, 151, 0.26);
  }
`;

const ManualSelector = styled.span`
  user-select: none;
  color: #858d99;
  cursor: pointer;
  width: 100%;
  text-align: center;

  svg {
    position: relative;
    top: 0.5rem;
    transform: rotateZ(${(p: MSP) => (p.isManual ? `180deg` : `0deg`)});
    transition: transform 0.2s linear;
  }

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;

const SelectorWrapper = styled(Hint)`
  && {
    grid-template-columns: 1fr;
    padding: 2.5rem;
    grid-gap: 2rem;
    line-break: anywhere;

    animation: fadein 0.3s linear;
    transform-origin: 0 0;

    @keyframes fadein {
      0% {
        opacity: 0;
        transform: scale(1, 0);
      }
      100% {
        opacity: 1;
        transform: scale(1, 1);
      }
    }
  }
`;

const StyledBigButton = styled(BigButton)`
  && {
    padding-left: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `5rem` : `8rem`};
    padding-right: ${(props: { device: DeviceType }) =>
      props.device === DeviceType.Desktop ? `5rem` : `8rem`};
    width: fit-content;
    border-radius: 3rem;
    justify-self: center;
  }
`;

const PointerIcon = () => (
  <StaticImage
    src="./pointer.svg"
    alt="pointer"
    loading="eager"
    placeholder="none"
    width={20}
  />
);

function kindToText(kind: AllMutationKind): string {
  if (kind === `appendAfter`) return `After`;
  if (kind === `appendBefore`) return `Before`;
  return `Replace`;
}
