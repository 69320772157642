import styled from 'styled-components';
import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { TextInput } from '@/components/text-input';
import { HSpace, VSpace } from '@/components/spacing';
import { SmallButton } from '@/components/small-button';
import { QBItemSelection } from '@/components/query-builder/models';
import { SelectOptions } from '@/components/small-select';
import { maybe } from '@/features/details/utils';
import { Checkbox } from '@/components/checkbox';

export const QBTypography = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;

  margin: 0;
  padding: 0;

  font-size: 1.4rem;
  font-weight: 600;
  font-family: JetBrains Mono, serif;
  text-align: start;

  color: black;
  background: transparent;
`;

export const QBCaption = styled(QBTypography)`
  && {
    color: #959ab5;
  }
`;

export const QBTextInput = styled(TextInput)`
  && {
    min-width: 25rem;
    max-width: 30rem;
    text-align: start;
    border-radius: 2rem;
    border: 1.5px solid #e8e8ea;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 0.55rem 2rem;
  }
`;

export const QBNumberInput = styled(TextInput)`
  && {
    min-width: 6rem;
    max-width: 8rem;
    text-align: center;
    border-radius: 2rem;
    border: 1.5px solid #e8e8ea;
    font-size: 1.4rem;
    font-family: 'JetBrains Mono', serif;
    box-shadow: none;
    padding: 0.55rem 1rem;
  }
`;

export const QBButton = styled(SmallButton)`
  && {
    box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.09),
      0 9px 13px 0 rgba(177, 217, 203, 0.18), 0 1px 3px 0 rgba(0, 0, 0, 0.05);

    color: #3754fa;
    letter-spacing: 0.27px;
    text-transform: uppercase;
    font-family: JetBrains Mono, serif;
    font-weight: bold;
    border: 1px solid rgba(151, 151, 151, 0.1);
    filter: drop-shadow(1px 1px 1px #0000);
    font-size: 1.15rem;
    justify-content: center;
    justify-items: center;
    align-items: center;
    height: 3.4rem;
  }
`;

export const OrButton = styled(QBButton)`
  && {
    width: 87%;
  }
`;

export function QBHSpace() {
  return <HSpace value={1} />;
}

export function QBVSpace() {
  return <VSpace value={2} />;
}

export function QBRightArrow() {
  return (
    <StyledArrow>
      <StaticImage
        objectFit="contain"
        src="../../assets/right_arrow_disabled.svg"
        alt="end"
        placeholder="none"
        loading="eager"
        height={10}
      />
    </StyledArrow>
  );
}
export function QBColon() {
  return <StyledColon>︰</StyledColon>;
}

export function QBRemove({
  onClick,
  shouldHide,
}: {
  shouldHide?: boolean;
  onClick?: () => void;
}) {
  return (
    <StyledX
      style={{
        opacity: shouldHide ? `0` : `1`,
        pointerEvents: shouldHide ? `none` : `auto`,
      }}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <StaticImage
        objectFit="contain"
        src="../../assets/close_x.svg"
        alt="end"
        placeholder="none"
        loading="eager"
        decoding="sync"
        height={8}
      />
    </StyledX>
  );
}

const StyledArrow = styled.div`
  align-self: center;
  margin: 0 1rem;
  .gatsby-image-wrapper {
    display: flex;
    filter: contrast(0.1);
    align-self: center;
  }
`;
const StyledColon = styled.div`
  font-weight: 900;
  font-size: 2rem;
  margin: 0 0.2rem;
  color: #aaaaaa;
  align-self: center;
`;

const StyledX = styled.div`
  cursor: pointer;
  background-color: #e7e7e8;
  padding: 0.8rem;
  border-radius: 50%;
  align-self: center;
  margin-left: 1rem;
  box-shadow: 0 9px 13px 0 rgba(177, 217, 203, 0.18),
    0 1px 3px 0 rgba(0, 0, 0, 0.05);

  transition: opacity 400ms ease-out;

  .gatsby-image-wrapper {
    display: flex;
    filter: contrast(0.1);
    align-self: center;
  }

  :hover {
    opacity: 0.8;
  }

  :active {
    opacity: 0.6;
  }
`;

export function shouldShowOrCondition(qbSelection: QBItemSelection): boolean {
  return !qbSelection.qbProps?.disableOrCond;
}

export const CustomOption =
  (options: Array<SelectOptions>, current: string) =>
  ({ innerRef, innerProps, children }) => {
    const option = maybe(() => options[innerProps.id.split(`option-`)[1]]);
    const isCurrent = option.key === current;
    const image = option?.image;
    return (
      <OptWrapper ref={innerRef} {...innerProps} active={isCurrent}>
        <img src={image} alt={children} />
        <HSpace />
        {children}
      </OptWrapper>
    );
  };
const OptWrapper = styled.div<{ active: boolean }>`
  display: flex;
  justify-content: start;
  align-items: center;

  img {
    object-fit: cover;
    object-position: center;
    border-radius: 5px;
    width: 50px;
    height: 50px;
  }

  ${(p) => (p.active ? `color: blue;` : ``)}
  padding: 0.7rem;

  :hover {
    cursor: pointer;
    color: #0095ff;
  }
`;

export function shouldShowUrlParamsCheckbox(current: QBItemSelection) {
  return current?.qbProps?.alsoApplyOnQueryParams;
}

export function UrlParamsCheckbox({
  current,
  onSelection,
  mt,
}: {
  mt?: string;
  current: QBItemSelection;
  onSelection: (bool) => void;
}) {
  if (!shouldShowUrlParamsCheckbox(current)) {
    return null;
  }
  return (
    <IncludeUrlParamsCheckbox mt={mt}>
      <Checkbox
        blueCheckmark
        initialValue={current.includeQueryParams}
        caption={
          <IncludeUrlParamsCheckboxCaption>
            Select to Include URL Parameters
          </IncludeUrlParamsCheckboxCaption>
        }
        onSelection={onSelection}
        size="small"
      />
      <VSpace value={1} />
    </IncludeUrlParamsCheckbox>
  );
}

const IncludeUrlParamsCheckboxCaption = styled.div`
  font-family: 'JetBrains Mono', serif;
  font-size: 1.15rem;
  color: #94969a;
  margin-left: -1rem;
`;
const IncludeUrlParamsCheckbox = styled.div<{ mt?: string }>`
  width: 80%;
  margin-top: ${({ mt }) => mt || `-1rem`};

  .checkbox_wrapper {
    color: #b2b6ba !important;
    background-color: #f3f3f3 !important;
    padding: 1rem 2rem;
    border-radius: 15px;
  }
`;
