import styled from 'styled-components';
import React, {
  createRef,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select, { StylesConfig } from 'react-select';
import QRCode from 'react-qr-code';
import { FaChevronLeft } from 'react-icons/fa';
import { toast } from 'react-toastify';
import { ExperienceVariant } from '@/webapi/use-experience-api';
import { AccountContext } from '@/features/account-context';
import { EditorContext } from '@/features/editor/context/editor-context';
import { maybe } from '@/features/details/utils';
import { BigButton } from '@/components/big-button';
import { breakpoints } from '@/components/responsive';
import { DELETED } from '@/features/details/shared';
import { VSpace } from '@/components/spacing';
import { hash } from '@/utils/cache';
import { openLinkInNewTab } from '@/utils/browser';

export function outsideButNotReactSelect(
  cb?: any,
  className?: string,
): (ev: MouseEvent) => void {
  return (ev: MouseEvent) => {
    let current = ev.target as HTMLElement;
    let found = false;
    while (current) {
      if (
        current?.className?.includes?.(className) ||
        current?.className?.includes?.(`preview-popover-new`) ||
        current?.id?.includes?.(`react-select`)
      ) {
        found = true;
        return;
      }
      current = current.parentElement;
    }
    if (cb && !found) {
      cb();
    }
  };
}

export interface PreviewPopoverContentProps {
  onPreview: (baseUrl?: string, variantId?: string) => Promise<string>;
  onSharePreview: (
    email: string,
    sharedBy: string,
    quickPreviewUrl?: string,
  ) => Promise<void | unknown>;
  quickPreviewUrl: string;
  togglePopover: () => void;
  variants: Array<ExperienceVariant>;
}

type PreviewState = `main` | `share`;

export function PreviewPopoverContentNew({
  onPreview,
  onSharePreview,
  quickPreviewUrl,
  togglePopover,
  variants,
}: PreviewPopoverContentProps) {
  const accountCtx = useContext(AccountContext);
  const editorCtx = useContext(EditorContext);

  const baseUrlRef = createRef<HTMLInputElement>();

  const [loading, setLoading] = useState(true);
  const [baseUrl, setBaseUrl] = useState(quickPreviewUrl);
  const [previewState, setPreviewState] = useState<PreviewState>(`main`);
  const [emailAddress, setEmailAddress] = useState(``);
  const [previewUrl, setPreviewUrl] = useState(`https://visually.io`);
  const [variantId, setVariantId] = useState(
    editorCtx?.experienceState?.currentExperience?.activeVariantId ??
      variants?.[0]?.id,
  );

  const variantOptions = useMemo(
    () =>
      variants?.map((v) => ({
        value: v.id,
        label: v.name,
      })),
    [variants],
  );

  const currentVariant = useMemo(
    () => variants?.find((v) => v.id === variantId),
    [variants, variantId],
  );

  const defaultVariantOption = useMemo(
    () => variantOptions.find((v) => v.value === variantId),
    [variantOptions, currentVariant],
  );

  const isMultiVariation = useMemo(
    () => maybe(() => variants.filter((v) => v.name !== DELETED).length > 1),
    [variants],
  );

  useEffect(() => {
    if (baseUrlRef.current) {
      baseUrlRef.current.focus();
      baseUrlRef.current.setSelectionRange(
        baseUrlRef.current.value.length,
        baseUrlRef.current.value.length,
      );
    }
  }, []);

  const experienceName = useMemo(() => {
    if (editorCtx?.experienceState) {
      return editorCtx.experienceState.currentExperience.name;
    }
    return `experience`;
  }, [editorCtx?.experienceState]);

  const onBaseUrlChanged = (ev) => {
    setBaseUrl(ev.target.value);
  };

  const onEmailAddressChanged = (ev) => {
    setEmailAddress(ev.target.value);
  };

  const previewHash = useMemo(
    () => hash(`${baseUrl}-${variantId}`),
    [baseUrl, variantId],
  );

  useEffect(() => {
    if (previewHash?.length > 5) {
      setLoading(true);
      onPreview(baseUrl, variantId).then((url) => {
        setPreviewUrl(url);
        setLoading(false);
      });
    }
  }, [previewHash]);

  const onCopyToClipboard = async () => {
    await navigator.clipboard.writeText(previewUrl);
    toast(`Preview link was copied successfully`, {
      theme: `colored`,
      type: `success`,
    });
    togglePopover();
  };

  const onOpenPreview = async () => {
    openLinkInNewTab(previewUrl, `vsly_preview`, togglePopover);
    togglePopover();
  };

  const onSharePreviewClicked = async () => {
    await onSharePreview(
      emailAddress,
      accountCtx?.account?.store?.firstName || `Visually User`,
      previewUrl,
    );
    toast(`Preview link was emailed to ${emailAddress}`, {
      theme: `colored`,
      type: `success`,
    });
    togglePopover();
  };

  return (
    <Wrapper className="exp-preview-content">
      {previewState === `share` && (
        <>
          <Back onClick={() => setPreviewState(`main`)}>
            <FaChevronLeft size={20} />
          </Back>
          <HeaderText>Email Preview Link</HeaderText>
          <Divider />
          <VSpace value={0.5} />
          <HeaderText className="small">Share a link with</HeaderText>
          <Input
            placeholder="Email address"
            defaultValue={emailAddress}
            onChange={onEmailAddressChanged}
          />
          <VSpace value={20.5} />
          <BigButton
            disabled={!emailAddress || loading}
            border="2px solid black"
            noTransform
            size="medium-high"
            fillWidth
            noShadow
            onClick={onSharePreviewClicked}
          >
            Share
          </BigButton>
        </>
      )}
      {previewState === `main` && (
        <>
          <HeaderText>
            Preview{` `}
            {experienceName === `experience`
              ? experienceName
              : `"${experienceName}"`}
            <br />
            on page
          </HeaderText>
          <Input
            defaultValue={baseUrl}
            onChange={onBaseUrlChanged}
            ref={baseUrlRef}
          />
          <Select
            isDisabled={!isMultiVariation}
            styles={selectStyles}
            placeholder="Select a variation"
            options={variantOptions}
            defaultValue={defaultVariantOption}
            onChange={(selected: any) => setVariantId(selected.value)}
          />
          <QRFrame>
            <QRCode value={previewUrl} size={100} />
            <Text>Scan to open the link on another device</Text>
          </QRFrame>
          <ButtonsContainer>
            <TwoColumns>
              <BigButton
                disabled={loading}
                border="2px solid #C8CACB"
                background="white"
                color="#97A0A8"
                noShadow
                noTransform
                size="medium-high"
                fillWidth
                onClick={() => setPreviewState(`share`)}
              >
                Email Link
              </BigButton>
              <BigButton
                disabled={loading}
                border="2px solid #C8CACB"
                background="white"
                color="#97A0A8"
                noShadow
                noTransform
                size="medium-high"
                fillWidth
                onClick={onCopyToClipboard}
              >
                Copy Link
              </BigButton>
            </TwoColumns>
            <BigButton
              disabled={loading}
              border="2px solid black"
              noTransform
              size="medium-high"
              fillWidth
              noShadow
              onClick={onOpenPreview}
            >
              {loading ? `...` : `Preview`}
            </BigButton>
          </ButtonsContainer>
        </>
      )}
    </Wrapper>
  );
}

const selectStyles = {
  menuPortal: (styles) => ({
    ...styles,
    zIndex: `9999999999999999`,
    border: `none`,
  }),
  menu: (styles) => ({
    ...styles,
    minWidth: `150px`,
    borderRadius: `1.5rem`,
    padding: `1rem`,
    border: `none`,
    boxShadow: `0 1px 1px 0 rgba(0,0,0,0.22), 0 5px 32px 0 rgba(182,207,205,0.47)`,
  }),
  menuList: (styles) => ({
    ...styles,
    border: `none`,
  }),
  group: (styles) => ({
    ...styles,
    color: `#9BA7B3`,
    fontSize: `1.6rem`,
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    fontSize: `1.6rem`,
    fontWeight: isSelected ? `bolder` : `bold`,
    textAlign: `start`,
    fontFamily: `Inter ,serif`,
    color: isSelected ? `#0023FF` : `#3E4B57`,
    background: `transparent`,
    cursor: `pointer`,
    ':hover': {
      color: isSelected ? `#0023FF` : `#0980ee`,
    },
    ':active': {
      background: `none`,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: `none`,
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: `0 0 0 0`,
    width: `1.4rem`,
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    padding: `0`,
    marginLeft: `1rem`,
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: `0 0 0px 0`,
  }),
  singleValue: (styles) => ({
    ...styles,
    padding: `0`,
    color: `#656E72`,
    fontFamily: `Inter ,serif`,
    fontWeight: `bold`,
    fontSize: `1.6rem`,
  }),
  control: (styles) => ({
    ...styles,
    cursor: `pointer`,
    fontSize: `1.6rem`,
    borderRadius: `1rem`,
    border: `solid 1px #d6d6d6`,
    '&:hover': {
      border: `solid 1px #d6d6d6`,
    },
    boxShadow: `0 !important`,
    padding: `1.8rem`,
    maxHeight: `6rem`,
  }),
  input: (styles) => ({
    ...styles,
    padding: `0`,
  }),
  placeholder: (styles) => ({
    ...styles,
  }),
} as StylesConfig;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  background: white;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  justify-content: flex-start;
  align-items: stretch;
  max-width: 40rem;
  min-width: 40rem;
  min-height: 52rem;
  box-shadow: 0 0.5px 3.5px 0 rgba(0, 0, 0, 0.05),
    0 9px 13px 0 rgba(177, 217, 203, 0.18), 0 4px 7px 0 rgba(0, 0, 0, 0.08);
  border: solid 0.6px rgba(151, 151, 151, 0.11);
  border-radius: 3rem;

  ${breakpoints.down(`md`)} {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

const Back = styled.div`
  position: absolute;
  left: 2rem;
  top: 2.3rem;
  color: #979797;
  cursor: pointer;
`;

const HeaderText = styled.p`
  margin: 0;
  font-size: 1.7rem;
  font-weight: bold;
  color: #000;
  font-family: 'Inter', sans-serif;
  text-align: center;
  line-height: 1.5;
  user-select: none;

  &&.small {
    font-size: 1.5rem;
    font-weight: normal;
  }
`;

const QRFrame = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3rem;
  background: #f7f7f7;
  padding: 1.5rem;
  border-radius: 2rem;
  justify-content: center;
  align-items: center;

  && > :nth-child(2) {
    padding-right: 3rem;
  }
`;

const Divider = styled.div`
  height: 1px;
  width: 100%;
  background-color: #d8d8d8;

  mask-image: linear-gradient(
    var(--mask-direction, to right),
    hsl(0 0% 0% / 0),
    rgb(248 249 251 / 1) 5%,
    rgb(248 249 251 / 1) 95%,
    hsl(0 0% 0% / 0)
  );
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.5rem;
`;

const Text = styled(HeaderText)`
  && {
    font-size: 1.5rem;
    font-weight: 500;
    color: #adb4bb;
    text-align: start;
  }
`;

const Input = styled.input`
  outline: none;
  border: solid 1px #d6d6d6;
  background-color: #fafafa;
  box-shadow: none;
  border-radius: 1rem;
  padding: 1.8rem;
  font-size: 1.8rem;
  color: #273440;

  &&::placeholder {
    font-size: 1.5rem;
  }

  &&:focus {
    border: solid 1px #d3d1d1;
    outline: none;
  }
`;
