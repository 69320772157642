import * as React from 'react';
import { useContext } from 'react';
import styled from 'styled-components';
import {
  TargetingDeviceType,
  Trigger,
  TriggerKind,
} from '@/webapi/use-experience-api';
import { TextInput } from '@/components/text-input';
import { CardButton, TextualCardLayout } from '@/components/card-button';
import { EditorContext } from '@/features/editor/context/editor-context';
import { useTriggersDefaults } from '@/features/editor/widgets/shared/modals/trigger/use-triggers-defaults';
import { Checkbox } from '@/components/checkbox';
import { Tooltip } from '@/components/tooltip';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';

export function SelectTrigger({
  onSelection,
  onSelectorChange,
  onTimeoutChange,
  onEventOnceChanged,
  selected: { kind, selector, timeoutMillis, elementEvent },
}: {
  selected: Trigger;
  onSelection: (value: boolean, trigger: Trigger) => void;
  onTimeoutChange: (ev) => void;
  onSelectorChange: (ev) => void;
  onEventOnceChanged: (state: boolean) => void;
}) {
  const {
    defaultPageLoad,
    defaultInactivity,
    defaultKlavioEvent,
    defaultElementHover,
    defaultElementClicked,
    defaultElement,
    defaultTimeout,
    defaultExitIntent,
    defaultJavascriptFunction,
  } = useTriggersDefaults();
  const editorContextProps = useContext(EditorContext);
  const klavioTriggerEnabled =
    !!editorContextProps?.resources?.klavioIntegrationEnabled;
  const isMobileTargeting =
    editorContextProps?.experienceState?.currentExperience?.targeting
      ?.device === TargetingDeviceType.MOBILE;
  const isAllDevices =
    editorContextProps?.experienceState?.currentExperience?.targeting
      ?.device === TargetingDeviceType.ALL_DEVICES;

  const isInactivityTriggerEnabled = useFeatureBit(
    FeatureBit.INACTIVITY_TRIGGER,
  );

  const formatSeconds = (seconds) => parseFloat(seconds.toFixed(2)).toString();

  const handleTimeout = (ev) => {
    const value = parseFloat(ev.target.value);
    if (Number.isNaN(value)) {
      return onTimeoutChange({
        target: { value: defaultTimeout.timeoutMillis },
      });
    }
    return onTimeoutChange(ev);
  };

  return (
    <Container>
      <Grid>
        <LineItem
          selected={kind === TriggerKind.PAGE_LOAD}
          onSelection={onSelection}
          trigger={defaultPageLoad}
        >
          <span>Page Load</span>
          <span>Trigger experience as soon as the page loads</span>
        </LineItem>
        <LineItem
          selected={kind === TriggerKind.TIMEOUT}
          onSelection={onSelection}
          trigger={defaultTimeout}
        >
          <span>Delayed</span>
          {kind === TriggerKind.TIMEOUT ? (
            <div>
              Trigger experience{` `}
              <TimeoutInput
                defaultValue={formatSeconds(timeoutMillis / 1000)}
                onChange={handleTimeout}
                autoFocus
                type="number"
                hasDecimal={formatSeconds(timeoutMillis / 1000).includes(`.`)}
              />
              seconds after page loads
            </div>
          ) : (
            <span>
              Trigger experience after the specified amount of seconds
            </span>
          )}
        </LineItem>
        <LineItem
          selected={kind === TriggerKind.ELEMENT_APPEARING}
          onSelection={onSelection}
          trigger={defaultElement}
        >
          <span>Element Appearing</span>
          {kind === TriggerKind.ELEMENT_APPEARING ? (
            <InputWrapper>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Trigger experience on element appear:
              <ElementSelectorInput
                defaultValue={selector}
                onChange={onSelectorChange}
              />
            </InputWrapper>
          ) : (
            <span>
              Trigger experience when the specified element appears on screen
            </span>
          )}
        </LineItem>
        <LineItem
          selected={kind === TriggerKind.ELEMENT_CLICKED}
          onSelection={onSelection}
          trigger={defaultElementClicked}
        >
          <span>Element Clicked</span>
          {kind === TriggerKind.ELEMENT_CLICKED ? (
            <InputWrapper>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Trigger experience on element click:
              <ElementEventInput
                selector={selector}
                onSelectorChange={onSelectorChange}
                elementEvent={elementEvent}
                onEventOnceChanged={onEventOnceChanged}
              />
            </InputWrapper>
          ) : (
            <span>
              Trigger experience when the specified element is being clicked
            </span>
          )}
        </LineItem>
        <LineItem
          selected={kind === TriggerKind.ELEMENT_HOVER}
          onSelection={onSelection}
          trigger={defaultElementHover}
        >
          <span>Element Hover</span>
          {kind === TriggerKind.ELEMENT_HOVER ? (
            <InputWrapper>
              {/* eslint-disable-next-line react/no-unescaped-entities */}
              Trigger experience on element hover:
              <ElementEventInput
                selector={selector}
                onSelectorChange={onSelectorChange}
                elementEvent={elementEvent}
                onEventOnceChanged={onEventOnceChanged}
              />
            </InputWrapper>
          ) : (
            <span>
              Trigger experience when the specified element is being hovered
            </span>
          )}
        </LineItem>
        {klavioTriggerEnabled && (
          <LineItem
            selected={kind === TriggerKind.KLAVIO_EVENT}
            onSelection={onSelection}
            trigger={defaultKlavioEvent}
          >
            <span>On Klaviyo Event</span>
            <span>
              Trigger Visitors Based on The Response to Klaviyo Offers
            </span>
          </LineItem>
        )}
        {!isMobileTargeting && (
          <LineItem
            selected={kind === TriggerKind.EXIT_INTENT}
            onSelection={onSelection}
            trigger={defaultExitIntent}
          >
            <span>
              Exit Intent
              {isAllDevices && (
                <span style={{ marginLeft: `0.5rem` }}>(DESKTOP ONLY)</span>
              )}
            </span>
            <span>
              Trigger experience when the user moves his cursor out of the
              browser window.
              {isAllDevices && (
                <span style={{ marginLeft: `0.5rem` }}>(DESKTOP ONLY)</span>
              )}
            </span>
          </LineItem>
        )}
        {isInactivityTriggerEnabled && (
          <LineItem
            selected={kind === TriggerKind.INACTIVITY}
            onSelection={onSelection}
            trigger={defaultInactivity}
          >
            <span>Inactivity</span>
            {kind === TriggerKind.INACTIVITY ? (
              <div>
                Inactivity
                <TimeoutInput
                  defaultValue={timeoutMillis / 1000}
                  onChange={onTimeoutChange}
                  autofocus
                  type="number"
                />
                seconds
              </div>
            ) : (
              <span>Trigger experience after ## seconds of inactivity</span>
            )}
          </LineItem>
        )}
        <LineItem
          selected={kind === TriggerKind.JAVASCRIPT_FUNCTION}
          onSelection={onSelection}
          trigger={defaultJavascriptFunction}
        >
          <span>Custom Javascript</span>
          <span>Write your own triggering logic using javascript</span>
        </LineItem>
      </Grid>
    </Container>
  );
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  grid-gap: 2.5rem;
`;

const TimeoutInput = styled(TextInput)`
  && {
    margin: 0 0.5rem;
    text-align: center;
    font-size: 1.4rem;
    padding: 0;
    box-shadow: none;
    border: 1px solid #9ba7b3;
    border-radius: 0.5rem;
    height: 2.2rem;
    width: ${(props) => (props.hasDecimal ? `40px` : `3rem`)};
  }
`;

const ElementSelectorInput = styled(TextInput)`
  && {
    margin-top: 0.3rem;
    height: 2.2rem;
    width: 100%;
    text-align: start;
    font-size: 1.2rem;
    padding: 0 1rem;
    box-shadow: none;
    border: 1px solid #9ba7b3;
    border-radius: 0.5rem;
  }
`;

const LineItem = ({
  children,
  trigger,
  selected,
  onSelection,
}: {
  trigger: Trigger;
  children: any;
  selected: boolean;
  onSelection: (value: boolean, trigger: Trigger) => void;
}) => (
  <CardButton
    padding="1rem"
    height="13rem"
    borderRadius="1.5rem"
    onClicked={(_, value) => onSelection(value, trigger)}
    selected={selected}
    canBeSelected
  >
    <TextualCardLayout
      verticalGap="1rem"
      padding="0 1.5rem"
      primaryColor={selected ? `#003CED` : `#576470`}
      accentColor={selected ? `#003CED` : `#758390`}
      primaryFontSize="1.4rem"
      accentFontSize="1.1rem"
      accentFontFamily="Inter"
      accentFontWeight="500"
      verticalDistribution="max-content max-content"
    >
      {children}
    </TextualCardLayout>
  </CardButton>
);

export const Container = styled.div`
  margin: 2rem 1.5rem;
  text-transform: none;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;

  text-align: center;
  color: #9ba7b3;
  letter-spacing: -0.38px;
  font-family: Inter, serif;
  font-weight: 500;
  font-size: 1.4rem;
`;

const InputWrapper = styled.div`
  && > * {
    margin-top: 0.8rem;
  }

  .checkbox_wrapper {
    grid-column-gap: 0 !important;
    grid-template-columns: 2.2rem 1fr;
  }
  .checkbox_wrapper * {
    font-size: 1rem;
  }

  .box_wrapper {
    height: 1.5rem;
    width: 1.5rem;
  }

  .checkbox-tooltip {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

function ElementEventInput({
  selector,
  onSelectorChange,
  elementEvent,
  onEventOnceChanged,
}: {
  selector: string;
  onSelectorChange: (ev) => void;
  elementEvent: {
    name: string;
    once: boolean;
  };
  onEventOnceChanged: (state: boolean) => void;
}) {
  return (
    <>
      <ElementSelectorInput
        defaultValue={selector}
        onChange={onSelectorChange}
      />
      <Checkbox
        disableCaptionToggle
        caption={
          <div className="checkbox-tooltip">
            SPA Event{` `}
            <Tooltip
              zIndex={999999999999999}
              title="SPA Event"
              text="Enable this checkbox if your site is a single-page application"
            />
          </div>
        }
        initialValue={
          !(elementEvent?.once === undefined ? false : elementEvent?.once)
        }
        onSelection={onEventOnceChanged}
      />
    </>
  );
}
