import { isBrowser } from '@/utils/browser';

export const breakpoints = {
  up,
  down,
  upH,
  downH,
};

export const globalBreakpointsW = {
  xs: 0,
  xsm: 330,
  sm: 600,
  md: 960,
  lg: 1280,
  lgr: 1440,
  xlr: 1790,
  xl: 1920,
  xxl: 2500,
};

const globalBreakpointsH = {
  xs: 0,
  sm: 200,
  md: 400,
  lg: 795,
  xl: 1200,
};

function up(breakpoint: string | number) {
  if (Number.isInteger(breakpoint)) {
    return `@media (min-width: ${breakpoint}px)`;
  }
  return `@media (min-width: ${globalBreakpointsW[breakpoint]}px)`;
}

function down(breakpoint: string | number) {
  if (Number.isInteger(breakpoint)) {
    return `@media (max-width: ${breakpoint}px)`;
  }
  return `@media (max-width: ${globalBreakpointsW[breakpoint]}px)`;
}

function upH(breakpoint: string) {
  return `@media (min-height: ${globalBreakpointsH[breakpoint]}px)`;
}

function downH(breakpoint: string) {
  return `@media (max-height: ${globalBreakpointsH[breakpoint]}px)`;
}

export function isMobile() {
  if (isBrowser()) {
    return window.innerWidth < globalBreakpointsW.md;
  }
  return false;
}
