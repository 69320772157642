import { useContext } from 'react';
import { EditorContext } from '@/features/editor/context/editor-context';
import { QBItemProp, QBType } from '@/components/query-builder/models';
import { SelectOptions } from '@/components/small-select';
import { PlacementKind } from '@/webapi/use-experience-api';
import { RecommendationType } from '@/webapi/use-widget-catalog-api';
import { NumberValueOps } from '@/components/query-builder/numeric-value';
import { useFeatureBit } from '@/features/account-context';
import { FeatureBit } from '@/webapi/use-auth-api';
import {
  hasAllVariantPrimaryAttrOptions,
  LoadedEditorResources,
} from '@/features/editor/use-editor-loader';
import { getProductValue } from '@/features/editor/widgets/shared/modals/use-audience-query-builder';

export function useConditionalQueryBuilder(strategyType: RecommendationType) {
  const {
    resources,
    experienceState: {
      currentExperience: { quickPreviewPlacement },
    },
  } = useContext(EditorContext);

  if (strategyType === RecommendationType.MANUAL) {
    return {
      condition: [
        {
          envKey: `variantPerProduct`,
          kind: QBType.MULTI_VALUE,
          caption: `Separate out color variants`,
          disableOrCond: true,
          disableOp: true,
          options: [
            { key: `yes`, value: `yes` },
            { key: `no`, value: `no` },
          ],
        },
        {
          envKey: `inventory`,
          kind: QBType.NUMERIC_VALUE,
          caption: `Inventory`,
          disableOrCond: true,
          defaultNumericValue: 0,
          defaultNumericValueOp: NumberValueOps.GT,
        },
        {
          envKey: `includeCartItems`,
          kind: QBType.MULTI_VALUE,
          caption: `Include Cart Items`,
          disableOrCond: true,
          disableOp: true,
          options: [
            { key: `no`, value: `no` },
            { key: `yes`, value: `yes` },
          ],
        },
      ],
    };
  }
  const collections = resources?.collections?.map?.(
    (col) =>
      ({
        key: col.handle,
        value: col.title,
      } as SelectOptions),
  );
  if (quickPreviewPlacement === PlacementKind.ALL_PRODUCTS) {
    collections.splice(1, 0, {
      key: `product_collections`,
      value: `Viewd Item Collections`,
    } as SelectOptions);
  }

  const isVariantAttributesEnabled =
    useFeatureBit(FeatureBit.VARIANT_ATTRIBUTES) &&
    hasAllVariantPrimaryAttrOptions(resources);

  const isEnabledSameTagsRecs =
    useFeatureBit(FeatureBit.RECS_SAME_TAG_COL) &&
    ![
      RecommendationType.PAST_PURCHASES,
      RecommendationType.RECENTLY_VIEWED,
      RecommendationType.CART_ITEMS,
    ].includes(strategyType);

  const tags = resources?.tags?.map?.(
    (tag) =>
      ({
        key: tag,
        value: tag,
      } as SelectOptions),
  );
  const condition: QBItemProp[] = [
    ...(strategyType === RecommendationType.PAST_PURCHASES
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `PURCHASED ITEM`,
            hasTimeframe: true,
            disableOrCond: true,
            options: [{ key: `True`, value: `True` }],
            envKey: RecommendationType.PAST_PURCHASES,
          },
        ]
      : []),
    ...(strategyType === RecommendationType.CART_ITEMS
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `ITEM ADDED TO CART`,
            hasTimeframe: true,
            disableOrCond: true,
            options: [{ key: `True`, value: `True` }],
            envKey: RecommendationType.CART_ITEMS,
          },
          {
            kind: QBType.ARRAY_VALUE,
            caption: `ITEM IS STILL IN THE CART`,
            hasTimeframe: false,
            disableOrCond: true,
            options: [
              { key: `True`, value: `True` },
              { key: `False`, value: `False` },
            ],
            envKey: RecommendationType.CART_ITEMS,
          },
        ]
      : []),
    {
      kind: QBType.ARRAY_VALUE,
      caption: `products with tag`,
      options: tags,
      envKey: `tag`,
    },
    {
      envKey: `category`,
      kind: QBType.MULTI_VALUE,
      caption: `products from collections`,
      options: collections,
    },
    {
      kind: QBType.ARRAY_VALUE,
      caption: `Product Name`,
      options: resources?.products?.map(
        (p) =>
          ({
            key: p.handle,
            image: p?.image?.src,
            value: getProductValue(p),
          } as SelectOptions),
      ),
      defaultIsNot: true,
      envKey: `product`,
    },
    {
      envKey: `inventory`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Inventory`,
      disableOrCond: true,
      defaultNumericValue: 0,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `price`,
      kind: QBType.NUMERIC_VALUE,
      caption: `Price`,
      disableOrCond: true,
      defaultNumericValueOp: NumberValueOps.GT,
    },
    {
      envKey: `free_shipping`,
      kind: QBType.NUMERIC_VALUE,
      caption: `free shipping goal`,
      disableOrCond: true,
      hasCurrencyCode: true,
      explain: `For example, if the cart total is $40 and the goal is $50, we will show products that cost more than $10`,
      hideCurrencyCodeCheckbox: true,
      disableOp: true,
      defaultNumericValueOp: NumberValueOps.IS,
    },
    ...(resources?.reviews?.reviewsEnabled
      ? [
          {
            envKey: `reviewsCount`,
            kind: QBType.NUMERIC_VALUE,
            caption: `Reviews Count`,
            disableOrCond: true,
            defaultNumericValueOp: NumberValueOps.GT,
          },
          {
            envKey: `reviewsRating`,
            kind: QBType.NUMERIC_VALUE,
            caption: `Reviews Rating`,
            disableOrCond: true,
            defaultNumericValueOp: NumberValueOps.GT,
          },
        ]
      : []),
    ...(isVariantAttributesEnabled
      ? [
          {
            envKey: `variantAttributes`,
            kind: QBType.ARRAY_VALUE,
            caption: `${resources.mainAttribute} (Variant primary attribute)`,
            options: getVariantAttributes(resources, isEnabledSameTagsRecs),
          },
          {
            envKey: `shuffle`,
            kind: QBType.MULTI_VALUE,
            caption: `Randomly Shuffle Results`,
            disableOrCond: true,
            disableOp: true,
            options: [
              { key: `no`, value: `no` },
              { key: `yes`, value: `yes` },
            ],
          },
          {
            envKey: `oos50p`,
            kind: QBType.MULTI_VALUE,
            caption: `Display products with no stock in most sizes`,
            disableOrCond: true,
            disableOp: true,
            options: [
              { key: `no`, value: `no` },
              { key: `yes`, value: `yes` },
            ],
          },
        ]
      : []),
    ...(isEnabledSameTagsRecs
      ? [
          {
            kind: QBType.ARRAY_VALUE,
            caption: `Include only items matching the same Tag`,
            disableOp: true,
            options: [
              { key: `all_matching_tags`, value: `All matching tags` },
              ...(tags || []),
            ],
            envKey: `same_tag`,
          },
          {
            envKey: `same_collection`,
            kind: QBType.ARRAY_VALUE,
            disableOp: true,
            caption: `Include only items matching the same Collection`,
            options: [
              {
                key: `all_matching_collections`,
                value: `All matching collections`,
              },
              ...(collections || []),
            ],
          },
        ]
      : []),
    {
      envKey: `variantPerProduct`,
      kind: QBType.MULTI_VALUE,
      caption: `Separate out color variants`,
      disableOrCond: true,
      disableOp: true,
      options: [
        { key: `yes`, value: `yes` },
        { key: `no`, value: `no` },
      ],
    },
    {
      envKey: `includeCartItems`,
      kind: QBType.MULTI_VALUE,
      caption: `Include Cart Items`,
      disableOrCond: true,
      disableOp: true,
      options: [
        { key: `no`, value: `no` },
        { key: `yes`, value: `yes` },
      ],
    },
  ];

  return { condition };
}

export function getVariantAttributes(
  resources: LoadedEditorResources,
  isEnabledSameTagsRecs: boolean,
) {
  const variantMainAttributes = resources.mainAttributeOptions.map((o) => ({
    key: o,
    value: o,
  }));
  return [
    isEnabledSameTagsRecs
      ? {
          key: `same_var_primary_attr`,
          value: `All products in the same ${resources.mainAttribute}`,
        }
      : undefined,
    ...variantMainAttributes,
  ].filter((v) => typeof v !== `undefined`);
}
